import React, { FC, useContext, useState } from "react";
import DetailedReportModal from "components/DetailedReportModal";
import { observer } from "mobx-react-lite";
import FLAG_ARTIST, {
  FLAG_ARTISTResponse,
  FLAG_ARTISTVariables,
} from "core/graphql/new/mutations/FlagArtist";
import { useMutation } from "@apollo/react-hooks";
import FeedbackModal from "./FeedbackModal";
import ReportFormContext from "stores/ReportFormStore";

interface ReportArtistModalProps {
  handleClose: () => void;
}

const ReportArtistModal: FC<ReportArtistModalProps> = observer(
  ({ handleClose }) => {
    const store = useContext(ReportFormContext);
    const [openFeedback, setOpenFeedback] = useState<boolean>(false);
    const [failed, setFailed] = useState<boolean>(false);

    const [submitArtistReport] = useMutation<
      FLAG_ARTISTResponse,
      FLAG_ARTISTVariables
    >(FLAG_ARTIST);

    const handleSubmit = async () => {
      if (store.id && store.reportedReason) {
        try {
          await submitArtistReport({
            variables: {
              artistId: store.id,
              reason: store.reportedReason,
              additonalComment: store.otherComment,
            },
          });
        } catch (error) {
          setFailed(true);
        }
      }
      setOpenFeedback(true);
    };

    const handleClosingFeedback = () => {
      handleClose();

      setOpenFeedback(false);

      setFailed(false);
    };

    return (
      <>
        {openFeedback ? (
          <FeedbackModal failed={failed} handleClose={handleClosingFeedback} />
        ) : (
          <DetailedReportModal
            handleClose={handleClose}
            handleSubmit={handleSubmit}
          />
        )}
      </>
    );
  }
);

export default ReportArtistModal;
