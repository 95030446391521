const convertTime = (timeInMilli?: number) => {
  if (!timeInMilli) {
    return;
  }

  var minutes = Math.floor(timeInMilli / 60);

  var seconds = timeInMilli % 60;

  var integer = Math.round(seconds);

  if (integer === 60) {
    minutes = minutes + 1;
    integer = 0;
  }

  const convertedTime = minutes + ":" + (integer < 10 ? 0 : "") + integer;

  return convertedTime;
};

export default convertTime;
