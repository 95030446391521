import gql from "graphql-tag";

const REMOVE_SONG_FROM_PLAYLIST = gql`
  mutation($songId: String, $playlistId: String) {
    removeSongFromPlaylist(playlistId: $playlistId, songId: $songId) {
      name
      songs {
        _id
        name
      }
    }
  }
`;

export interface REMOVE_SONG_FROM_PLAYLISTVariables {
  playlistId: string;
  songId: string;
}

export default REMOVE_SONG_FROM_PLAYLIST;
