import React, { FC } from "react";
import { Link, RouteComponentProps, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks/lib/useQuery";
import GET_ALBUM_BY_ID, {
	GET_ALBUM_BY_IDResponse,
	GET_ALBUM_BY_IDVariables,
} from "core/graphql/new/queries/findAlbumById";
import PageWithBackgroundImage from "components/@ui/PageWithBackgroundImage";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Hidden from "@material-ui/core/Hidden/Hidden";
import IconButton from "@material-ui/core/IconButton/IconButton";
import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import SongList from "containers/SongList";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "@material-ui/core";
import { DateTime } from "luxon";
import TipArtist from "pages/Desktop/components/TipArtistModal";
import PlayButton from "components/Album/PlayButton";
import ShareButton from "components/Album/ShareButton";
import MoreMenuButton from "components/MoreMenuButton";
import { PlaylistType } from "models/Player";

interface IResponsiveDashboardProps
	extends RouteComponentProps<{ id: string }> { }

interface UseStylesParams {
	image?: string;
}


const useStyles = ({ image = "" }: UseStylesParams) =>
	makeStyles((theme) =>
		createStyles({
			image: {
				backgroundImage: `url('${image}')`,
				backgroundPosition: "center",
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				width: "100vw",
				height: "100vw",
				maxWidth: 300,
				maxHeight: 300,
				borderRadius: 6,
				marginRight: theme.spacing(2),
				[theme.breakpoints.down("md")]: {
					marginBottom: theme.spacing(2),
				},
			},
			block1: {
				display: "flex",
				alignItems: "center",
				flexWrap: "wrap",
				padding: `20vh ${theme.spacing(8)}px 0px`,
				[theme.breakpoints.down("md")]: {
					padding: theme.spacing(2),
				},
			},
			block2: {
				padding: `${theme.spacing(8)}px`,
				[theme.breakpoints.down("md")]: {
					padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
				},
			},
			backButton: {
				width: "100%",
				display: "flex",
				paddingRight: "10px",
				paddingTop: "10px",
			},

			description: {
				padding: `0px ${theme.spacing(8)}px`,
				[theme.breakpoints.down("md")]: {
					padding: `0px ${theme.spacing(2)}px ${theme.spacing(2)}px`,
				},
			},
			tipButton: {
				background: "white",
				color: "#386d6a",
				width: "135px",
				borderRadius: "100px",
				marginTop: "16px",
			},
			margining: {
				marginTop: 10,
				marginLeft: 10,
			},
		})
	);

const AlbumResponsive: FC<IResponsiveDashboardProps> = ({ match }) => {

	const { data } = useQuery<GET_ALBUM_BY_IDResponse, GET_ALBUM_BY_IDVariables>(
		GET_ALBUM_BY_ID,
		{ variables: { id: match.params.id } }
	);

	const theme = useTheme();
	const history = useHistory();

	const classes = useStyles({ image: data?.album.imageUrl })();

	const handleBack = () => {
		history.goBack();
	};


	return (
		<PageWithBackgroundImage>
			<>
				<Hidden mdUp={true}>
					<Box className={classes.backButton}>
						<IconButton onClick={handleBack}>
							<ChevronLeftIcon />
						</IconButton>
					</Box>
				</Hidden>
				<Box className={classes.block1}>
					<Box className={classes.image} />

					<Box display="flex" flexDirection="column">
						<Typography gutterBottom={true} variant="h1">
							{data?.album.title}
						</Typography>

						<Typography gutterBottom={true} variant="h4">
							{data?.album.primaryArtist?._id ? (
								<>
									By{" "}
									<Link
										style={{
											textDecoration: "none",
											color: theme.palette.text.primary,
										}}
										to={`/artist/${data?.album.primaryArtist?._id}`}
									>
										{data?.album.primaryArtist?.displayName}
									</Link>
								</>
							) : (
								<Skeleton />
							)}
						</Typography>

						<Typography gutterBottom={true}>
							{data?.album.releaseDate
								? DateTime.fromISO(data?.album.releaseDate).toFormat("yyyy")
								: "2021"}{" "}
							{data?.album.songs?.filter((song) => song.disabled !== true)
								.length || 0}
							Songs
						</Typography>

						<div style={{ display: "flex" }}>
							<PlayButton
								songs={data?.album.songs?.filter(
									(song) => song.disabled !== true
								)}
								image={data?.album.imageUrl}
							/>

							<ShareButton
								image={data?.album.imageUrl}
								url={data?.album.shortLink}
								title={data?.album.title}
							/>

						</div>
						<TipArtist
							buttonStyle={classes.tipButton}
							artistId={data?.album.primaryArtist?._id}
							artistImage={data?.album.primaryArtist?.image}
							artistName={data?.album.primaryArtist?.displayName}
						/>

						<Box className={classes.margining}>
							<MoreMenuButton type="album" id={data?.album._id} />
						</Box>
					</Box>
				</Box>

				{data && data.album.description && (
					<Box className={classes.description} mt={3}>
						<Typography variant="body2">{data?.album.description}</Typography>
					</Box>
				)}

				<Box className={classes.block2}>
					<Typography gutterBottom={true} variant="h4">
						Album Tracks
					</Typography>

					<SongList
						songs={data?.album.songs?.filter((song) => song.disabled !== true)}
						playlistType={PlaylistType.ALBUM}
						playlistId={data?.album._id}
					/>
				</Box>

				<Box height={200} pb={10} />
			</>
		</PageWithBackgroundImage>
	);
};

export default AlbumResponsive;
