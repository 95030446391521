import gql from "graphql-tag";

const setAutoPlay = gql`
  mutation setAutoPlay($autoPlay: Boolean!) {
    setAutoPlay(autoPlay: $autoPlay) {
      _id
      autoPlaySongs
    }
  }
`;

export interface setAutoPlayResponse {
  user: NewUser;
}

export interface setAutoPlayVariables {
  autoPlay: boolean;
}

export default setAutoPlay;
