import React, { FC, useEffect } from "react";

import { observer } from "mobx-react-lite";

import Box from "components/@ui/Box";

import { useState } from "react";
import PlayButton from "components/Player/PlayButton";
import FavoriteSong from "containers/FavoriteSong";

import FullScreenModal from "./FullScreenModal";
import "core/utils/StringPrototype";
import FocusedSongModal from "containers/MusicPlayerModal/FocusedSongModal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import Hidden from "@material-ui/core/Hidden/Hidden";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import MusicPlayerSlider from "./MusicPlayerSlider";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import InsufficientFundsAlert from "./InsufficientFundsAlert";
import usePlayer from "context/PlayerContext";
import GET_SONG_BY_ID, {
  GET_SONG_BY_IDResponse,
  GET_SONG_BY_IDVariables,
} from "core/graphql/new/queries/getSongById";
import { useLazyQuery } from "@apollo/react-hooks";
import ModalControls from "./MusicPlayerModal/ModalControls";
import Grid from "@material-ui/core/Grid/Grid";
import AutoplayButton from "components/@ui/AutoplayButton";
import VolumeControls from "components/Player/VolumeControls";

const useStyles = (image?: string) =>
  makeStyles((theme) =>
    createStyles({
      controls: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "70%",
        [theme.breakpoints.down("sm")]: {
          width: "auto",
        },
      },
      musicplayerContainer: {
        display: "flex",
        justifyContent: "space-between",
        padding: "10px 20px 10px 10px",
        alignItems: "center",
      },
      closeModal: {
        height: "10%",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-center",
      },
      focusedSongModal: {
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-end",
        alignItems: "center",
        textAlign: "center",
      },
      headings: {
        display: "flex",
        alignItems: "center",
        width: "250px",
        minWidth: "250px",
        marginRight: "50px",
        [theme.breakpoints.down("xs")]: {
          width: "70%",
          minWidth: "70%",
          marginRight: "0px",
        },
      },
      musicPlayerWidth: { width: "100%", minWidth: "80px" },
      imageStyle: {
        backgroundImage: `url('${image}')`,
        backgroundSize: "cover",
        minHeight: 50,
        minWidth: 50,
      },
      textStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      textContainer: {
        maxWidth: "100%",
        overflow: "hidden",
      },

    })
  );

interface IMusicPlayerProps { }

const MusicPlayer: FC<IMusicPlayerProps> = () => {
  const player = usePlayer();
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [getSong, { data }] = useLazyQuery<
    GET_SONG_BY_IDResponse,
    GET_SONG_BY_IDVariables
  >(GET_SONG_BY_ID);
  const classes = useStyles(data?.song.image)();

  const handleGetSong = async () => {
    if (!player.currentSong) {
      return;
    }

    try {
      await getSong({
        variables: {
          songId: player.currentSong,
        },
      });
    } catch (e) {

    }
  };

  useEffect(() => {
    if (!player.currentSong) {
      return;
    }

    handleGetSong();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player.currentSong]);

  useEffect(() => {
    if (player.error) {
      setDialogOpen(true);
    }
  }, [player.error]);

  const handleDetailToggle = () => {
    if (open === true) {
      setOpen(!open);
    }

    if (window.innerWidth < 959.95) {
      setOpen(!open);
    }
  };

 

  const playButtonClick = () => {
    if (!player.playing) {
      player.play();
      return;
    }
    player.pause();
  };

  return (
    <>
      <Box onClick={handleDetailToggle}>
        <Box className={classes.musicplayerContainer} pr={3}>
          <Box className={classes.headings}>
            <Box className={classes.imageStyle}></Box>

            <Box className={classes.textContainer} ml={1.25}>
              <Typography noWrap variant="h5" className={classes.textStyle}>
                {data?.song.name}
              </Typography>

              <Typography
                noWrap
                className={classes.textStyle}
                variant="body2"
                color="textSecondary"
              >
                {data?.song.primaryArtist?.displayName}
              </Typography>
            </Box>
          </Box>

          <Box className={classes.controls}>
            <Hidden mdUp={true}>
              <PlayButton
                onClick={playButtonClick}
                loading={player.loading}
                isPlaying={player.playing}
              />
            </Hidden>

            <Hidden smDown={true}>
              <Box width="100%" mr={5}>
                <Grid alignItems="center" container>
                  <Hidden smDown={true}>
                    <Grid sm={12} md={12} lg={5} xl={5} item>
                      <ModalControls horizontal />
                    </Grid>
                  </Hidden>

                  <Grid sm={12} md={12} lg={7} xl={7} item>
                    <MusicPlayerSlider horizontal />
                  </Grid>
                </Grid>
              </Box>

              <VolumeControls />
              <FavoriteSong whiteGradient songId={data?.song._id} />
            </Hidden>

            <Hidden xsDown={true}>
              <Box ml={1}>
                <AutoplayButton />
              </Box>
            </Hidden>
          </Box>
        </Box>
      </Box>

      <FullScreenModal
        id="detailed-player"
        style={{
          zIndex: 3000,
          backgroundColor: "#02172a",
          position: "relative",
          overflow: "hidden",
        }}
        open={open}
        setOpen={setOpen}
        onClose={handleDetailToggle}
      >
        <Box className={classes.closeModal}>
          <IconButton onClick={handleDetailToggle}>
            <KeyboardArrowDownIcon fill="white" />
          </IconButton>
        </Box>

        <Box className={classes.focusedSongModal}>
          <FocusedSongModal
            id={data?.song._id}
            title={data?.song.name}
            artist={data?.song.primaryArtist?.displayName}
            album={data?.song.album?.title}
            art={data?.song.image}
          />
        </Box>
      </FullScreenModal>
      <InsufficientFundsAlert open={dialogOpen} handleClose={setDialogOpen} />
    </>
  );
};

export default observer(MusicPlayer);
