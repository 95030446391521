import React, { FC, useState, useContext } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography";
import GET_USER_PLAYLISTS, {
  GET_USER_PLAYLISTSResponse,
} from "core/graphql/new/queries/getUserPlaylists";
import Grid from "@material-ui/core/Grid/Grid";
import NewPlaylistDialog from "./NewPlaylistDialog";
import { useQuery } from "@apollo/react-hooks/lib/useQuery";
import PlaylistSelectItems from "./PlaylistSelectItems";
import TokenManager from "core/auth/TokenManager";

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      input: {
        height: "25px",
        borderRadius: "25px",
        background: "rgb(255 255 255 / 0.55)",
        border: "none",
        paddingLeft: "10px",
        color: "rgb(255 255 255 / 0.75)",
        "&::-webkit-input-placeholder": {
          color: "rgb(0 0 0 / 0.5)",
        },
        "&:focus": {
          outline: "none",
          background: "rgb(255 255 255 / 0.65)",
        },
      },
      label: {
        color: "rgba(255, 255, 255, 0.7)",
        fontWeight: "bold",
        marginBottom: "20px",
      },
      topLabel: {
        textAlign: "center",
        color: "rgba(255,255,255,.65)",
        fontWeight: "bold",
      },
      button: {
        marginTop: "30px",
        backgroundColor: "rgba(102, 199, 179, 0.75)",
        color: "rgba(255,255,255,0.7)",
        width: "150px",
        height: "40px",
        borderRadius: "25px",
        marginBottom: "20px",
        border: "none",
        "&:hover": {
          cursor: "pointer",
        },
      },
      buttonContainer: {
        display: "flex",
        paddingLeft: "20px",
        alignItems: "center",
        background: "rgb(255 255 255 / .15)",
        width: "100%",
        justifyContent: "space-between",
      },

      contentContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      },
      formContainer: {
        width: "60%",
        display: "flex",
        flexDirection: "column",
        paddingLeft: "30px",
        [theme.breakpoints.down("md")]: {
          paddingLeft: "0px",
          paddingTop: "20px",
          width: "95%",
        },
      },
      box: {
        paddingBottom: "75px",
        [theme.breakpoints.down("md")]: {
          paddingBottom: "15px",
        },
      },
    })
  );

interface AddToPlaylistDialogProps {
  songId?: string;
  closeMenu?: any;
}

const AddToPlaylistDialog: FC<AddToPlaylistDialogProps> = ({
  songId,
  closeMenu,
}) => {
  const { data } = useQuery<GET_USER_PLAYLISTSResponse>(GET_USER_PLAYLISTS);

  const classes = useStyles()();
  const [open, setOpen] = useState(false);
  const manager = useContext(TokenManager);
  const token = manager.getToken();

  const handleClickOpen = () => {
    if (token === 'undefined') {
      window.location.href = "/login"
    }
    else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    closeMenu();
  };

  return (
    <div>
      <Typography onClick={handleClickOpen} variant="body1">
        Add to Playlist
      </Typography>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            backgroundColor: "rgb(54 68 76 / 0.9)",
            maxWidth: "700px",
            width: "80%",
          },
        }}
      >
        <DialogTitle id="form-dialog-title" className={classes.topLabel}>
          SELECT PLAYLIST
        </DialogTitle>
        <DialogContent>
          <Grid spacing={1} container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Box className={classes.box}>
                <NewPlaylistDialog />
              </Box>
            </Grid>

            {data?.playlists.map((playlist) => (
              <Grid item={true} xs={4}>
                <PlaylistSelectItems
                  image={
                    playlist.image ||
                    `https://image.shutterstock.com/image-illustration/vintage-vinyl-records-out-box-600w-1711637752.jpg`
                  }
                  playlistId={playlist._id}
                  title={playlist.name}
                  songId={songId}
                  handleClose={handleClose}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </div>
  );
};

export default AddToPlaylistDialog;
