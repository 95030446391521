import gql from "graphql-tag";

const TIP_ARTIST = gql`
  mutation($artistId: String, $amount: Float) {
    tipArtist(artistId: $artistId, amount: $amount) {
      _id
    }
  }
`;

export interface TIP_ARTISTVariables {
  artistId: string;
  amount: number;
}

export interface TIP_ARTISTResponse {
  tip: Tip;
}

export default TIP_ARTIST;
