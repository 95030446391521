import React from "react";

import { BrowserRouter, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import TokenValidation from "containers/TokenValidation";
import Unauthenticated from "./Unauthenticated";
import SearchResponsive from "./Search/SearchResponsive";
import ArtistResponsive from "./ArtistPage/ArtistResponsive";
import PlaylistPage from "./Playlist/PlaylistPage";
import AlbumResponsive from "./Album/AlbumResponsive";
import LibraryResponsive from "./Library/LibraryResponsive";
import LoginResponsive from "./Login/LoginResponsive";
import Registration from "./Registration/Registration";
import Layout from "./Layout";
import DashboardPage from "pages/Dashboard/Dashboard";
import LikedSongsMobile from "./Library/LikedSongs";
import ResetPassword from "./ResetPassword";
import PlayerContextProvider from "containers/PlayerContext";
import GenrePage from "./Genre";
import PaymentPage from "./Payments";
import Account from "./AccountPage";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const routes = {
  unsecure: [
    { path: "/login", component: LoginResponsive, exact: true },
    { path: "/unauthenticated", component: Unauthenticated, exact: false },
    // { path: "/artist/:id", component: ArtistResponsive, exact: true },
    // { path: "/album/:id", component: AlbumResponsive, exact: true },
    {
      path: "/registration",
      component: Registration,
      exact: true,
    },
    {
      path: "/reset-password",
      component: ResetPassword,
      exact: false,
    },
  ],

  test: [{ path: "/payment", component: PaymentPage, exact: true }],

  secure: [
    { path: "/", component: DashboardPage, exact: true },
    { path: "/library", component: LibraryResponsive, exact: false },
    { path: "/account", component: Account, exact: false },
    { path: "/search", component: SearchResponsive, exact: true },
    { path: "/artist/:id", component: ArtistResponsive, exact: true },
    { path: "/album/:id", component: AlbumResponsive, exact: true },
    {
      path: "/playlist/:playlistId",
      component: PlaylistPage,
      exact: true,
    },
    {
      path: "/genre/:id",
      component: GenrePage,
      exact: true,
    },
    { path: "/likedsongs", component: LikedSongsMobile, exact: true },
    // {
    //   path: "/library/recently-played",
    //   component: DesktopRecentlyPlayed,
    //   exact: true,
    // },
  ],
};

export const history = createBrowserHistory();

const key = process.env.REACT_APP_STRIPE_PK;

if (!key) {
  throw new Error("strike pk does not exist");
}

const promise = loadStripe(key);

function Root() {
  return (
    <BrowserRouter>
      <Switch>
        {routes.unsecure.map(({ path, component, exact }) => (
          <Route key={path} path={path} component={component} exact={exact} />
        ))}

        <TokenValidation>
          <Elements stripe={promise}>
            <Switch>
              <Route
                key="/payment"
                path="/payment"
                component={PaymentPage}
                exact={false}
              />

              <PlayerContextProvider>
                <Layout>
                  {routes.secure.map(({ path, component, exact }) => (
                    <Route
                      key={path}
                      path={path}
                      component={component}
                      exact={exact}
                    />
                  ))}
                </Layout>
              </PlayerContextProvider>
            </Switch>
          </Elements>
        </TokenValidation>
      </Switch>
    </BrowserRouter>
  );
}

export default Root;
