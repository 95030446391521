import gql from "graphql-tag";

const GET_USERS_LIKED_SONGS = gql`
  query {
    songs: getUsersLikedSongs {
      name
      _id
      fileLink
      disabled
      primaryArtist {
        displayName
        _id
        image
      }
      album {
        title
        disabled
        _id
      }
      image
      costPerPlay
    }
  }
`;

export interface GET_USERS_LIKED_SONGSResponse {
  songs: NewSong[];
}

export default GET_USERS_LIKED_SONGS;
