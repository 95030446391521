import React, { FC } from "react";

import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";

import Root from "pages/Root";

import theme from "theme";

const App: FC = () => (
  <ThemeProvider theme={theme}>
    <CssBaseline />

    <Root />
  </ThemeProvider>
);

export default App;
