import React, { FC } from "react";

import Hidden from "@material-ui/core/Hidden";
import PageWithBackgroundImage from "components/@ui/PageWithBackgroundImage";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import ImageWithTitles from "components/@ui/ImageWithTitles";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import GET_USER_PLAYLISTS, {
  GET_USER_PLAYLISTSResponse,
} from "core/graphql/new/queries/getUserPlaylists";
import { useQuery } from "@apollo/react-hooks/lib/useQuery";
import Grid from "@material-ui/core/Grid/Grid";
import NewPlaylistDialog from "pages/Desktop/components/NewPlaylistDialog";

interface ILibraryProps {}

const useStyles = () =>
  makeStyles((theme) =>
    createStyles({
      block1: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        padding: `20vh ${theme.spacing(8)}px 0px`,
        [theme.breakpoints.down("sm")]: {
          padding: theme.spacing(2),
        },
      },
      block2: {
        padding: `${theme.spacing(8)}px`,
        [theme.breakpoints.down("sm")]: {
          padding: `${theme.spacing(2)}px ${theme.spacing(2)}px`,
        },
      },
      box: {
        paddingBottom: "75px",
        [theme.breakpoints.down("sm")]: {
          paddingBottom: "15px",
        },
      },
    })
  );

const LibraryResponsive: FC<ILibraryProps> = (props) => {
  const classes = useStyles()();

  const { data } = useQuery<GET_USER_PLAYLISTSResponse>(GET_USER_PLAYLISTS);

  return (
    <>
      <PageWithBackgroundImage>
        <Box className={classes.block1}>
          <Box>
            <Typography gutterBottom={true} variant="h1" color="primary">
              Library
            </Typography>
          </Box>
        </Box>

        <Box className={classes.block2}>
          <Grid container>
            <Hidden mdUp={true}>
              <Grid item sm={12} xs={12}>
                <Box className={classes.box}>
                  <NewPlaylistDialog />
                </Box>
              </Grid>
            </Hidden>

            <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
              <Box className={classes.box}>
                <ImageWithTitles
                  horizontal
                  title="Liked Songs"
                  titleLink={`/likedsongs`}
                  image={`https://i.pinimg.com/originals/73/15/da/7315da676f55e4707ea1766bdbd4e110.png`}
                />
              </Box>
            </Grid>

            {data?.playlists.map((playlist) => (
              <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                <Box className={classes.box}>
                  <ImageWithTitles
                    image={
                      playlist.image ||
                      "https://image.shutterstock.com/image-illustration/vintage-vinyl-records-out-box-600w-1711637752.jpg"
                    }
                    title={playlist.name}
                    titleLink={`/playlist/${playlist._id}`}
                    horizontal
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box height={200} pb={10} />
      </PageWithBackgroundImage>
    </>
  );
};

export default LibraryResponsive;
