import gql from "graphql-tag";

const GET_RECENT_ALBUMS = gql`
  query {
    albums: getRecentlyUploaded(limit: 15) {
      _id
      title
      createdAt
      disabled
      primaryArtist {
        _id
        displayName
      }
      isSingle
      imageUrl
      releaseDate
    }
  }
`;

export interface GET_RECENT_ALBUMSResponse {
  albums: Array<NewAlbum>;
}

export default GET_RECENT_ALBUMS;
