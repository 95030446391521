import gql from "graphql-tag";

const GET_SONG_BY_ID = gql`
  query findSong($songId: MongoID!) {
    song: findSong(filter: { _id: $songId }) {
      name
      _id
      image
      disabled
      album {
        disabled
        title
        _id
      }
      primaryArtist {
        displayName
        _id
        image
      }
      costPerPlay
    }
  }
`;

export interface GET_SONG_BY_IDVariables {
  songId: string;
}

export interface GET_SONG_BY_IDResponse {
  song: NewSong;
}

export default GET_SONG_BY_ID;
