import { Typography } from "@material-ui/core";
import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TransactionItem from "components/@ui/TransactionItem";
import DualLazy from "./lazyloader";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Box from "components/@ui/Box";

const useStyles = makeStyles<Theme>((theme) => ({
  header: {
    backgroundColor: "#FD4060",
    width: "100%",
    padding: "10px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  container: {
    width: "100%",
    marginTop: "9vh",
    [theme.breakpoints.down("md")]: {
      marginTop: "5vh",
    },
    [theme.breakpoints.up("lg")]: {
      height: "90%",
      maxHeight: "90%",
    },
  },
  list: {
    [theme.breakpoints.up("lg")]: {
      maxHeight: "90%",
      padding: "0px 20px 0px 10px",
      overflowY: "scroll",
      "&::-webkit-scrollbar": {
        width: "5px",
        height: "5px",
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "rgba(0,0,0,0.2)",
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(255,255,255,0.2)",
        borderRadius: "100px",
      },
      "&::-webkit-scrollbar-corner": {
        display: "none",
      },
    },
  },
  spinner: {
    color: "white",
  },
  emptyResponse: {
    width: "60%",
  },
}));

interface IUserTransactionsProps {
  transactions?: Transaction[];
  loading?: boolean;
}

const UserTransactions: FC<IUserTransactionsProps> = ({
  transactions,
  loading = false,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <Typography style={{ color: "white" }} variant="h4">
          Transactions
        </Typography>
        {loading && (
          <CircularProgress
            size={20}
            className={classes.spinner}
            color="primary"
          />
        )}
      </div>

      <div className={classes.list}>
        {loading ? (
          [...Array(5)].map((e, index) => (
            <TransactionItem loading={loading} key={index} />
          ))
        ) : (
          <>
            {transactions && transactions.length > 0 ? (
              transactions.map((t) => (
                <DualLazy
                  height={59}
                  throttle={250}
                  debounce={false}
                  once
                  offset={0}
                >
                  <TransactionItem
                    costOfCharge={t.total}
                    description={t.description}
                    date={t.createdAt}
                    formOfPayment={t.chargeType}
                  />
                </DualLazy>
              ))
            ) : (
              <Box width="100%" display="flex" justifyContent="center" mt={4}>
                <Typography className={classes.emptyResponse} align="center">
                  You haven't made any transactions yet.
                  <br />
                  Make your first transaction today by adding funds into your
                  account to start listening to music.
                </Typography>
              </Box>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default UserTransactions;
