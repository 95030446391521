import React, { FC } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography/Typography";

interface IThemeProps {
  mr: number;
  active: boolean;
}

const useStyles = makeStyles<Theme, IThemeProps>((theme) => ({
  container: {
    position: "relative",
    width: "45%",
    maxWidth: "220px",
    background: "#2e7362",
    border: "2px solid #2e7362",
    height: "fit-content",
    borderRadius: "20px",
    marginRight: (props) => props.mr,
    marginBottom: "24px",
  },
  box: {
    width: "100%",
    padding: "25px",
    backgroundColor: (props) => (props.active === true ? "#2e7362" : "#02172A"),
    borderRadius: "20px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#2e7362",
    },
  },
  banner: {
    width: "100%",
    padding: "2px",
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "12px",
  },
}));

interface IPaymentAmountSelectProps {
  price: number;
  banner?: string;
  mr?: number;
  active?: boolean;
  selectPrice: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const PaymentAmountSelect: FC<IPaymentAmountSelectProps> = ({
  price,
  banner,
  mr = 0,
  active = false,
  selectPrice,
}) => {
  const classes = useStyles({ mr, active });

  const handleSelect = () => {
    selectPrice(active ? undefined : price);
  };

  return (
    <div onClick={handleSelect} className={classes.container}>
      {banner && <div className={classes.banner}>{banner}</div>}

      <div className={classes.box}>
        <Typography align="center" variant="h3">
          ${price}
        </Typography>
      </div>
    </div>
  );
};

export default PaymentAmountSelect;
