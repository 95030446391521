import React, { FC, useState } from "react";
import { useLocation, Redirect, useHistory } from "react-router-dom";
import { useEffect } from "react";
import {
  makeStyles,
  Button,
  Box,
  TextField,
  Typography,
} from "@material-ui/core";

import RESET_PASSWORD, {
  RESET_PASSWORDResponse,
  RESET_PASSWORDVariables,
} from "../core/graphql/new/mutations/resetPassword";
import { useContext } from "react";
import TokenManagerContext from "../core/auth/TokenManager";
import useUnauthenticatedClient from "../jobs/UnathenicatedClient";
import envs from "../config/envs";
import { observer } from "mobx-react-lite";

interface IResetPasswordProps {}

const useStyles = makeStyles({
  root: {
    borderRadius: 50,
  },
  underline: {
    "&&:before": {
      width: "0%",
    },
    "&&:after": {
      //   width: "0%",
    },
  },
  textfield: {
    width: "260px",
    height: "40px",
    background: "rgba(255, 255, 255, 0.32)",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
  },

  container: {
    width: "100%",
    height: "100%",
    background: "url(https://www.starfarm.com/static/media/hero.dcc34059.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  link: {
    textDecoration: "none",
    "&:focus": {
      textDecoration: "none",
    },
  },
});

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const ResetPassword: FC<IResetPasswordProps> = (props) => {
  const queryParams = useQueryParams();
  const token = queryParams.get("token");
  const classes = useStyles();

  const history = useHistory();

  const manager = useContext(TokenManagerContext);

  const [error, setError] = useState("");

  const client = useUnauthenticatedClient();

  const [password, setPassword] = useState("");
  const [confirm, setConfirm] = useState("");

  useEffect(() => {
    manager.clearToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!token) {
      setError("token is invalid");
      return;
    }

    if (!password || !confirm) {
      setError("You must supply both fields");
      return;
    }

    if (password !== confirm) {
      setError("Passwords do not match");
      return;
    }

    if (password.length < 7) {
      setError("Password must be atleast 7 characters long");
      return;
    }

    const res = await client.mutate<
      RESET_PASSWORDResponse,
      RESET_PASSWORDVariables
    >({
      mutation: RESET_PASSWORD,
      variables: {
        password,
        resetPasswordToken: token,
      },
    });

    if (res.data) {
      const tokenRecieved = res.data.resetPassword;

      manager.setToken(tokenRecieved);
      manager.storeToken(tokenRecieved);
    }
  };

  useEffect(() => {
    if (!manager.token) {
      return;
    }

    if (manager.isBeneficiary) {
      window.location.href = envs.beneUri!;
      return;
    }

    history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [manager.token]);

  if (!token) {
    return <Redirect to="/login" />;
  }

  return (
    <Box className={classes.container}>
      <Box>
        <Typography gutterBottom={true} align="center" variant="h1">
          Create New Password
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            value={password}
            onChange={(e) => {
              setError("");

              return setPassword(e.target.value);
            }}
            margin="normal"
            fullWidth={true}
            variant="filled"
            placeholder="password"
            type="password"
          />

          <TextField
            value={confirm}
            onChange={(e) => {
              setError("");

              return setConfirm(e.target.value);
            }}
            margin="normal"
            fullWidth={true}
            variant="filled"
            placeholder="confirm"
            type="password"
          />

          <Typography
            gutterBottom={true}
            align="center"
            variant="body1"
            color="error"
          >
            {error}
          </Typography>

          <Box width="100%" display="flex" justifyContent="center">
            <Button
              type="submit"
              style={{
                backgroundColor: "#66C6B2",
                width: "205px",
                height: "40px",
                borderRadius: "25px",
                color: "#02172A",
              }}
            >
              Create Password
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default observer(ResetPassword);
