import { FlagReasons } from "FlagReasons";
import gql from "graphql-tag";

const FLAG_ARTIST = gql`
  mutation($artistId: String, $reason: String, $additionalComment: String) {
    flagArtist(artistId: $artistId, reason: $reason, additionalComment: $additionalComment){
        _id
    }
  }
`;

export interface FLAG_ARTISTVariables {
 artistId: string;
 reason: FlagReasons;
 additonalComment?: string;
}

export interface FLAG_ARTISTResponse {
    artist: Artist
}


export default FLAG_ARTIST;