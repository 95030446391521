import { DateTime } from "luxon";

interface point {
  y: number;
  x: string;
}

const convertPlaysToXY = (plays?: Transaction[]) => {
  const todaysDate = DateTime.local();
  // console.log(todaysDate, "aaj")
  // console.log(plays, "as")

  const thisMonthsPlays = plays
  // plays?.filter(
  //   (item) =>
  //     // console.log(DateTime.fromISO(item.createdAt!).get("month"))
  //     DateTime.fromISO(item.createdAt!).get("month") ===
  //     todaysDate.get("month") &&
  //     DateTime.fromISO(item.createdAt!).get("year") === todaysDate.get("year")
  // );
  // console.log(thisMonthsPlays, "this")

  const playsWithCreatedAt = thisMonthsPlays!.map((play) => ({
    ...play,
    createdAt: DateTime.fromISO(play.createdAt!),
  }));

  // console.log(playsWithCreatedAt, "at")

  const sortedPlays = playsWithCreatedAt.sort(
    (a, b) => a.createdAt.toMillis() - b.createdAt.toMillis()
  );

  // console.log(sortedPlays, "sorted")

  const oldestDate = sortedPlays[0].createdAt;



  let daysOfHistory = Math.ceil(todaysDate.diff(oldestDate, "days").days);

  let dates = [...Array(daysOfHistory + 1)]
    .map((day, index) => {
      return oldestDate.plus({ day: index });
    })
    .filter((date) => date.startOf("day") < DateTime.local());

  const points = dates?.map((date, index) => {
    const playsOnDay = sortedPlays.filter(
      (play) => play.createdAt.toSQLDate() === date.toSQLDate()
    );

    return {
      x: date.toSQLDate(),
      y: playsOnDay.length,
    };
  });

  const labels = points.map((point) => point.x);

  return { data: points, labels: labels };
};

export default convertPlaysToXY;
