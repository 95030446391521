import Button from "@material-ui/core/Button/Button";
import usePlayer from "context/PlayerContext";
import React, { FC, useContext, useState } from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { Dialog, Typography, Grid } from "@material-ui/core";
import Box from "components/@ui/Box";
import { useMutation } from "@apollo/react-hooks";
import setAutoPlay, {
  setAutoPlayResponse,
  setAutoPlayVariables,
} from "core/graphql/new/mutations/setAutoPlay";
import GET_CURRENT_USER from "core/graphql/new/queries/getCurrentUser";
import EventMessageContext from "stores/EventMessage";
import starfarmLogo from "../../picture/starFarmLarge.png";
import TokenManager from "core/auth/TokenManager";
interface StyleProps {
  image?: string;
}

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  playButton: { borderRadius: 80, width: 100 },
  dialogPaper: {
    margin: 0,
    backgroundColor: "#061c2d",
    maxWidth: "700px",
    width: "80%",
    height: "70%",
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    height: "100%",
    justifyContent: "space-evenly",
    paddingTop: "10px",
    paddingBottom: "10px",
    position: "relative",
  },
  modalHeader: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "2%",
  },
  donateButton: {
    width: "80%",
    borderRadius: 100,
    background: "#3D7670",
    color: "white",
    "&:hover": {
      cursor: "pointer",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "20px",
    },
  },
  addFundsButton: {
    width: "80%",
    borderRadius: 100,
    borderColor: "white",
    backgroundColor: "#2C3E4D",
    border: "1px solid white",
    "&:hover": {
      cursor: "pointer",
    },
  },
  image: {
    backgroundImage: (props) => `url(${props.image})`,
    height: "150px",
    width: "150px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "none",
  },
}));

interface IPlayButtonProps {
  songs?: NewSong[];
  image?: string;
}

const PlayButton: FC<IPlayButtonProps> = ({ songs, image }) => {
  const [messageOpen, setMessageOpen] = useState(false);
  const manager = useContext(TokenManager);
  const token = manager.getToken();


  const alertManager = useContext(EventMessageContext);

  const [setAuto] = useMutation<setAutoPlayResponse, setAutoPlayVariables>(
    setAutoPlay,
    {
      refetchQueries: [{ query: GET_CURRENT_USER }],
    }
  );
  const player = usePlayer();


  const currentSongIndex = player.currentSongOrder.findIndex(
    (s) => s === player.currentSong
  );


  const classes = useStyles({ image: image || starfarmLogo });

  const playSongs = (force?: boolean) => {
    if (token === 'undefined') {
      window.location.href = "/login"
    }


    if (!songs) {
      return;
    }

    if (!player.autoPlay && force === undefined) {
      setMessageOpen(true);
      return;
    }
   
    if (currentSongIndex>-1) {   
      player.currentSong = player.currentSongOrder[-1];
    }

    const songIds = songs.map((song) => song._id);
    player.selectSong(songIds[0], songIds);
  };

  const setAutoPlayClick = async () => {
    player.setAutoPlay(!player.autoPlay);

    setMessageOpen(false);

    playSongs();

    alertManager.setEvent("success", "", `Auto Play has been enabled`);

    await setAuto({
      variables: {
        autoPlay: player.autoPlay,
      },
    });
  };

  return (
    <>
      <Button
        color="primary"
        className={classes.playButton}
        variant="contained"
        onClick={() => playSongs()}
      >
        PLAY
      </Button>

      <Dialog
        open={messageOpen}
        onClose={() => setMessageOpen(false)}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <Box className={classes.contentContainer}>
          <Box className={classes.modalHeader}>
            <Typography variant="h3">Auto Play</Typography>
          </Box>

          <Box className={classes.image} />

          <Typography variant="body1">
            Enable auto play to automatically jump to the next song in your
            queue for an uninterrupted listening experience.
          </Typography>

          <Grid justify="space-between" container>
            <Grid item md={6} lg={6} xs={12} sm={6}>
              <Button
                onClick={() => {
                  playSongs(true);
                  setMessageOpen(false);
                }}
                className={classes.addFundsButton}
              >
                Ignore
              </Button>
            </Grid>

            <Grid item md={6} lg={6} sm={6} xs={12}>
              <Button
                onClick={setAutoPlayClick}
                className={classes.donateButton}
              >
                Auto Play
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default PlayButton;
