import React, { FC } from "react";

import { makeStyles } from "@material-ui/core";

import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "#02172a",
    height: "100vh",
  },
});

export interface IFullScreenModalProps {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  style?: React.CSSProperties;
  id: string;
  onClose: () => void;
}

const FullScreenModal: FC<IFullScreenModalProps> = ({
  open = false,
  children,
  style = {},
  id,
  onClose,
}) => {
  const classes = useStyles();

  return (
    <SwipeableDrawer
      id={id}
      style={style}
      classes={{ paper: classes.paper }}
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={() => ""}
      disableSwipeToOpen={true}
    >
      {children}
    </SwipeableDrawer>
  );
};

export default FullScreenModal;
