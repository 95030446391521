import gql from "graphql-tag";

const REMOVE_SONG_FROM_USER_LIKES = gql`
  mutation($id: String!) {
    RemoveSongFromUserLikes: removeSongFromUserLikes(id: $id) {
      _id
      likedSongs {
        _id
      }
    }
  }
`;

export interface REMOVE_SONG_FROM_USER_LIKESResonse {
  id: any;
}

export interface REMOVE_SONG_FROM_USER_LIKESVariables {
  id: string;
}

export default REMOVE_SONG_FROM_USER_LIKES;
