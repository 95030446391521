import { Box, Button, Typography } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden/Hidden";
import React, { FC, useEffect, useState } from "react";
import PaymentInformation from "./@ui/PaymentInfo";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import CheckBoxWithInfo from "./@ui/CheckBoxWithInfo";
import setStripePaymentMethod, {
  setStripePaymentMethodResponse,
  setStripePaymentMethodVariables,
} from "core/graphql/new/mutations/setStripePaymentMethod";
import GET_USER_PAYMENT_METHOD, {
  GET_USER_PAYMENT_METHODResponse,
} from "core/graphql/new/queries/getUserPaymentMethod";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import addFunds, {
  addFundsResponse,
  addFundsVariables,
} from "core/graphql/new/mutations/addFunds";
import { useHistory } from "react-router-dom";
import FullScreenModal from "containers/FullScreenModal";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton/IconButton";
import StarfarmLogo from "./@ui/StarfarmLogo";
import Container from "@material-ui/core/Container/Container";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

interface IResponsivePaymentInfoProps {
  paymentAmount?: number;
}

const useStyles = makeStyles<Theme>((theme) => ({
  icon: { marginRight: "10px", height: "32px", width: "32px" },
  exisitingCardContainer: {
    border: "2px solid white",
    padding: "8px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textButton: {
    padding: "5px",
    color: "#FD4060",
    marginLeft: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    backgroundColor: "#FD4060",
    color: "white",
    padding: "10px",
    borderRadius: "10px",
  },
  cancelButton: {
    marginRight: "20px",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    borderRadius: "10px",
  },
  smallConfirm: {
    backgroundColor: "#2e7362",
    color: "white",
    padding: "10px",
    borderRadius: "10px",
  },
  modalButton: {
    width: "100%",
    backgroundColor: "#FD4060",
    color: "white",
    padding: "10px",
    borderRadius: "100px",
    marginTop: "25px",
  },
  cancelModalButton: {
    width: "100%",
    backgroundColor: "white",
    color: "black",
    padding: "10px",
    borderRadius: "100px",
    marginTop: "25px",
  },
  paymentContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    paddingBottom: "50px",
  },
  root: {
    "&$checked": {
      color: "#FD4060",
    },
  },
  checked: {},
}));

//put all payment information and confirm payment stuff into here

const ResponsivePaymentInfo: FC<IResponsivePaymentInfoProps> = ({
  paymentAmount,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [modalOpen, setModalOpen] = useState(false);

  const [saveCard, setSaveCard] = useState(false);

  const [errors, setErrors] = useState<string | undefined>(undefined);

  const [setStripePaymentMethodMutation] = useMutation<
    setStripePaymentMethodResponse,
    setStripePaymentMethodVariables
  >(setStripePaymentMethod);

  const { data } = useQuery<GET_USER_PAYMENT_METHODResponse>(
    GET_USER_PAYMENT_METHOD
  );

  const [addFundsMutation] = useMutation<addFundsResponse, addFundsVariables>(
    addFunds
  );

  const [cardPreview, setCardPreview] = useState<string | undefined>(undefined);

  const updateCardLastFour = () => {
    setErrors(undefined);

    if (!data) {
      return;
    }

    setCardPreview(data?.paymentMethod);
  };

  useEffect(() => {
    updateCardLastFour();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.paymentMethod]);

  const handleConfirmPayment = async () => {
    if (!paymentAmount) {
      return;
    }

    let paymentMethodToUse: string | undefined = undefined;

    setErrors(undefined);

    if (cardPreview === undefined) {
      if (!stripe || !elements) {
        return;
      }

      const cardElement = elements.getElement("cardNumber");

      if (!cardElement) {
        return;
      }

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        setErrors(error.message);
        return;
      }

      if (!paymentMethod) {
        return;
      }

      paymentMethodToUse = paymentMethod.id;

      if (saveCard) {
        try {
          await setStripePaymentMethodMutation({
            variables: {
              paymentMethodId: paymentMethod.id,
            },
          });
        } catch (err) {
          setErrors(err.graphQLErrors[0].message);
          return;
        }
      }
    }

    if (errors) {
      return;
    }

    try {
      await addFundsMutation({
        variables: {
          amount: paymentAmount * 100,
          stripePaymentMethodId: paymentMethodToUse,
        },
      });

      history.push("/");
    } catch (err) {
      setErrors(err.graphQLErrors[0].message);
    }
  };

  const classes = useStyles();

  return (
    <>
      <Box paddingBottom="10px">
        <Typography>Payment Information</Typography>
      </Box>

      {cardPreview ? (
        <Box className={classes.exisitingCardContainer}>
          <Box display="flex" alignItems="center">
            <CreditCardIcon fill="grey" className={classes.icon} />
            <Typography variant="h4">Card Info</Typography>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography variant="body1">{cardPreview}</Typography>
            <Hidden xsDown={true}>
              <Typography
                className={classes.textButton}
                onClick={() => setCardPreview(undefined)}
                variant="body1"
              >
                Change
              </Typography>
            </Hidden>

            <Hidden smUp={true}>
              <Typography
                className={classes.textButton}
                onClick={() => {
                  setCardPreview(undefined);
                  setModalOpen(true);
                }}
                variant="body1"
              >
                Change
              </Typography>
            </Hidden>
          </Box>
        </Box>
      ) : (
        <>
          <Hidden smUp={true}>
            <Box
              onClick={() => setModalOpen(true)}
              className={classes.exisitingCardContainer}
            >
              <Box display="flex" alignItems="center">
                <CreditCardIcon fill="grey" className={classes.icon} />
                <Typography variant="h4">Payment Information</Typography>
              </Box>
            </Box>
          </Hidden>

          <Hidden xsDown={true}>
            <PaymentInformation errors={errors} />

            <Box display="flex" alignItems="center">
              <Checkbox
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
                checked={saveCard}
                onClick={() => setSaveCard(!saveCard)}
              />

              <Typography variant="subtitle1">Save card info</Typography>
            </Box>

            <Box display="flex" alignItems="center">
              {data?.paymentMethod && (
                <Button
                  className={classes.cancelButton}
                  onClick={updateCardLastFour}
                >
                  Cancel
                </Button>
              )}
              <Button
                disabled={paymentAmount ? false : true}
                className={classes.button}
                onClick={() => handleConfirmPayment()}
              >
                Confirm Payment
              </Button>
            </Box>
          </Hidden>
        </>
      )}

      <CheckBoxWithInfo
        title="Automatic Reloads"
        info="Never let your balance drop to $0, we’ll reload your account to your chosen price whenever your funds are running short that month so you can always stay connected to your music."
      />

      {cardPreview && (
        <Button
          disabled={paymentAmount && data?.paymentMethod ? false : true}
          className={classes.modalButton}
          onClick={() => handleConfirmPayment()}
        >
          Confirm
        </Button>
      )}

      <Hidden smUp={true}>
        <FullScreenModal
          id="payment-info"
          style={{
            zIndex: 3000,
            backgroundColor: "#02172a",
            position: "relative",
            overflow: "hidden",
            maxHeight: "100vh",
          }}
          open={modalOpen}
          setOpen={setModalOpen}
          onClose={() => {
            setModalOpen(false);
            setCardPreview(data?.paymentMethod);
          }}
        >
          <Container className={classes.paymentContainer}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
              mb={3}
            >
              <StarfarmLogo size={75} />
              <IconButton
                onClick={() => {
                  setModalOpen(false);
                  setCardPreview(data?.paymentMethod);
                }}
              >
                <CloseIcon fontSize="large" fill="white" />
              </IconButton>
            </Box>

            <Typography>Payment Information</Typography>

            <PaymentInformation errors={errors} />

            <Box display="flex" alignItems="center">
              <Checkbox
                classes={{
                  root: classes.root,
                  checked: classes.checked,
                }}
                checked={saveCard}
                onClick={() => setSaveCard(!saveCard)}
              />

              <Typography variant="subtitle1">Save card info</Typography>
            </Box>

            <div>
              <Button
                disabled={paymentAmount ? false : true}
                onClick={() => handleConfirmPayment()}
                className={classes.modalButton}
              >
                Confirm Payment
              </Button>

              <Button
                onClick={() => {
                  setModalOpen(false);
                  setErrors(undefined);
                  setCardPreview(data?.paymentMethod);
                }}
                className={classes.cancelModalButton}
              >
                cancel
              </Button>
            </div>
          </Container>
        </FullScreenModal>
      </Hidden>
    </>
  );
};

export default ResponsivePaymentInfo;
