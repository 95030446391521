import React, { FC } from "react";
import { useSubscription, useQuery } from "@apollo/react-hooks";
import WALLET_BALANCE_SUB, {
  WalletBalanceSubResponse,
} from "../core/graphql/subscriptions/walletBalance";
import GET_CURRENT_USER, {
  GET_CURRENT_USERResponse,
} from "../core/graphql/new/queries/getCurrentUser";
import Typography from "@material-ui/core/Typography/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import DollarValue from "../components/@ui/DollarValue";
import { Variant } from "@material-ui/core/styles/createTypography";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface IAccountBalanceProps {
  style?: string;
  variant?: "inherit" | Variant | undefined;
}

const useStyles = makeStyles({
  text: {
    color: "#58c3b1",
    fontSize: "16px",
  },
});

const AccountBalance: FC<IAccountBalanceProps> = ({ style, variant }) => {
  const { data: userData, loading } = useQuery<GET_CURRENT_USERResponse>(
    GET_CURRENT_USER,
    { pollInterval: 1000 }
  );

  const classes = useStyles();

  useSubscription<WalletBalanceSubResponse>(WALLET_BALANCE_SUB);

  return (
    <Typography
      variant={variant ? variant : "body1"}
      className={style ? style : classes.text}
    >
      {loading ? (
        <SkeletonTheme color="#1a2e3e" highlightColor="#22374a">
          <Skeleton />
        </SkeletonTheme>
      ) : (
        <DollarValue
          value={(userData?.currentUser?.balanceInPennies || 0) / 100}
        />
      )}
    </Typography>
  );
};

export default AccountBalance;
