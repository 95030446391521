import React, { FC } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import DollarValue from "./DollarValue";
import { formatDistanceToNow, parseJSON } from "date-fns";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

interface ITheme {
  formOfPayment?: string;
  loading: boolean;
}

const useStyles = makeStyles<Theme, ITheme>((theme) => ({
  itemContainer: {
    width: "100%",
  },
  paymentForm: {
    color: (props) =>
      props.formOfPayment === "CARD_PAYMENT"
        ? "#5661C2"
        : theme.palette.text.primary,
    fontWeight: "bold",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  cost: {
    color: (props) =>
      props.formOfPayment === "CARD_PAYMENT" ? "#56C2BC" : "#FD4060",
    fontWeight: "bold",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  date: {
    fontWeight: "bold",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  textContainer: {
    maxWidth: "100%",
    overflow: "hidden",
    margin: (props) => (props.loading ? "0px 10px" : "0px 5px"),
  },
  textStyle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  grid: {
    width: "100%",
    padding: "20px 10px",
    borderBottom: "1px solid rgba(255, 255, 255, 0.21)",
    alignItems: "center",
  },
}));

interface ITransactionItemProps {
  date?: string;
  description?: string;
  formOfPayment?: string;
  costOfCharge?: number;
  loading?: boolean;
}

const TransactionItem: FC<ITransactionItemProps> = ({
  date,
  description,
  formOfPayment,
  costOfCharge,
  loading = false,
}) => {
  const classes = useStyles({ formOfPayment, loading });

  return (
    <Grid className={classes.grid} container>
      <Grid xl={4} lg={4} md={4} sm={4} xs={4} item>
        <div className={classes.textContainer}>
          <Typography className={classes.date}>
            {loading ? (
              <SkeletonTheme color="#1a2e3e" highlightColor="#22374a">
                <Skeleton />
              </SkeletonTheme>
            ) : (
              <>{formatDistanceToNow(parseJSON(date!))}</>
            )}
          </Typography>
        </div>
      </Grid>
      <Grid xl={4} lg={4} md={4} sm={4} xs={4} item>
        <div className={classes.textContainer}>
          <Typography className={classes.textStyle}>
            {loading ? (
              <SkeletonTheme color="#1a2e3e" highlightColor="#22374a">
                <Skeleton />
              </SkeletonTheme>
            ) : (
              description
            )}
          </Typography>
        </div>
      </Grid>
      <Grid xl={2} lg={2} md={2} sm={2} xs={2} item>
        <div className={classes.textContainer}>
          <Typography className={classes.paymentForm}>
            {loading ? (
              <SkeletonTheme color="#1a2e3e" highlightColor="#22374a">
                <Skeleton />
              </SkeletonTheme>
            ) : formOfPayment === "CARD_PAYMENT" ? (
              "Card"
            ) : formOfPayment === "COST_OF_PLAY" ? (
              "Play"
            ) : (
              ""
            )}
          </Typography>
        </div>
      </Grid>
      <Grid xl={2} lg={2} md={2} sm={2} xs={2} item>
        <Typography align="right" className={classes.cost}>
          {loading ? (
            <SkeletonTheme color="#1a2e3e" highlightColor="#22374a">
              <Skeleton />
            </SkeletonTheme>
          ) : (
            <>
              {" "}
              {formOfPayment === "CARD_PAYMENT" ? "+ " : "- "}
              <DollarValue value={costOfCharge ? costOfCharge / 100 : 0} />{" "}
            </>
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default TransactionItem;
