import React, { FC, useMemo, useContext } from "react";

import { observer } from "mobx-react-lite";

import FavoriteOutlineIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteIcon from "@material-ui/icons/FavoriteRounded";

import { useMutation, useQuery } from "@apollo/react-hooks";

import ADD_SONGS_TO_USER_LIKES, {
  ADD_SONGS_TO_USER_LIKESResonse,
  ADD_SONGS_TO_USER_LIKESVariables,
} from "core/graphql/new/mutations/AddSongToUserLikes";
import GET_USERS_LIKED_SONGS, {
  GET_USERS_LIKED_SONGSResponse,
} from "core/graphql/new/queries/getUsersLikedSongs";
import REMOVE_SONG_FROM_USER_LIKES, {
  REMOVE_SONG_FROM_USER_LIKESResonse,
  REMOVE_SONG_FROM_USER_LIKESVariables,
} from "core/graphql/new/mutations/RemoveSongFromUserLikes";
import { makeStyles, Theme } from "@material-ui/core/styles";
import TokenManager from "core/auth/TokenManager";


interface IFavoriteSongProps {
  fontSize?: "large" | "small";
  songId?: string;
  whiteGradient?: boolean;
}

const useStyles = makeStyles<Theme>((theme) => ({
  buttonActiveWhite: {
    color: "#fff",
    "&:hover": {
      cursor: "pointer",
    },
  },
  button: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const FavoriteSong: FC<IFavoriteSongProps> = observer(
  ({ fontSize = "small", songId, whiteGradient = false }) => {
    const classes = useStyles();
    const manager = useContext(TokenManager);
    const token = manager.getToken();
    const [addSongToUserLikes] = useMutation<
      ADD_SONGS_TO_USER_LIKESResonse,
      ADD_SONGS_TO_USER_LIKESVariables
    >(ADD_SONGS_TO_USER_LIKES, {
      refetchQueries: [{ query: GET_USERS_LIKED_SONGS }],
    });

    const [removeFavorite] = useMutation<
      REMOVE_SONG_FROM_USER_LIKESResonse,
      REMOVE_SONG_FROM_USER_LIKESVariables
    >(REMOVE_SONG_FROM_USER_LIKES, {
      refetchQueries: [{ query: GET_USERS_LIKED_SONGS }],
    });

    const { data } = useQuery<GET_USERS_LIKED_SONGSResponse>(
      GET_USERS_LIKED_SONGS
    );



    const handleAddToFavorites = async (
      e: React.MouseEvent<SVGSVGElement, MouseEvent>
    ) => {
      e.stopPropagation();
      if (token === 'undefined') {
        window.location.href = "/login"
      }

      if (!songId) {
        return;
      }

      await addSongToUserLikes({
        variables: {
          id: songId,
        },
      });
    };

    const handleRemoveFromFavorites = async (
      e: React.MouseEvent<SVGSVGElement, MouseEvent>
    ) => {
      e.stopPropagation();

      if (!songId) {
        return;
      }

      removeFavorite({
        variables: {
          id: songId,
        },
      });
    };

    const isFavorited = useMemo(() => {
      if (!data || !data.songs) {
        return;
      }

      return data.songs.findIndex((song) => song._id === songId) > -1;
    }, [data, songId]);





    if (isFavorited) {
      return (
        <FavoriteIcon
          className={whiteGradient ? classes.buttonActiveWhite : classes.button}
          onClick={handleRemoveFromFavorites}
          color="primary"
          fontSize={fontSize as any}
        />
      );
    }

    return (
      <FavoriteOutlineIcon
        className={classes.button}
        onClick={handleAddToFavorites}
        fontSize={fontSize as any}
      />
    );
  }
);

export default FavoriteSong;
