import React, { FC } from "react";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import { makeStyles, createStyles, Hidden, Theme } from "@material-ui/core";
import FavoriteSong from "containers/FavoriteSong";
import PlaylistMoreButton from "pages/Desktop/components/playlistcomponents/PlaylistMoreButton";
import { Skeleton } from "@material-ui/lab";
import { toDollarValue } from "components/@ui/DollarValue";
import { PlaylistType } from "models/Player";
import usePlayer from "context/PlayerContext";
import { observer } from "mobx-react-lite";

interface INewSongItemProps {
	songName?: string;
	artistName?: string;
	songId?: string;
	albumId?: string;
	artistId?: string;
	onPlaySong?: (songId: string, index: number) => void;
	playlistId?: string;
	playlistType: PlaylistType;
	index?: number;
	costPerPlay?: number;
	artistImage?: string;
}

interface IThemeProps {
	currentSong: boolean;
}

const useStyles = makeStyles<Theme, IThemeProps>((theme) =>
	createStyles({
		track: {
			display: "flex",
			alignItems: "center",
			backgroundColor: "#071C2D",
			padding: `${theme.spacing(2)}px ${theme.spacing(0)}px ${theme.spacing(
				2
			)}px  ${theme.spacing(2)}px `,

			marginBottom: theme.spacing(1),
			borderRadius: 6,
			cursor: "pointer",
			"&&:hover": {
				backgroundColor: "#041523",
			},
		},
		songName: {
			color: (props) =>
				props.currentSong
					? theme.palette.secondary.main
					: theme.palette.text.primary,
		},
	})
);

const NewSongItem: FC<INewSongItemProps> = ({
	songName,
	artistName,
	costPerPlay,
	songId,
	albumId,
	artistId,
	onPlaySong,
	playlistId,
	playlistType,
	index = 0,
	artistImage,
}) => {
	const player = usePlayer();

	const classes = useStyles({
		currentSong:
			player.currentSong === songId &&
			player.playingFromPlaylistType === playlistType &&
			player.playingFromPlaylistId === playlistId,
	});

	const handlePlay = () => {
		if (!onPlaySong || !songId) {
			return;
		}

		onPlaySong(songId, index);
	};

	return (
		<Grid className={classes.track} container={true}>
			<Grid container item xs={8} onClick={handlePlay}>
				<Grid item xs={2} md={2}>
					<Typography color="primary" noWrap>
						{costPerPlay ? (
							toDollarValue(costPerPlay / 100)
						) : (
							<Skeleton width={10} />
						)}
					</Typography>
				</Grid>

				<Grid item xs={10} md={5}>
					<Typography className={classes.songName} noWrap>
						{songName}
					</Typography>
				</Grid>

				<Hidden mdUp={true}>
					<Grid item xs={2}></Grid>
				</Hidden>
				<Grid item xs={10} md={4}>
					<Typography noWrap>{artistName}</Typography>
				</Grid>
			</Grid>

			<Grid
				spacing={2}
				alignItems="center"
				justify="flex-end"
				container
				item
				xs={4}
			>
				<Grid item>
					<FavoriteSong songId={songId} />
				</Grid>

				<Grid item>
					<PlaylistMoreButton
						albumId={albumId}
						artistId={artistId}
						songId={songId}
						playlistId={playlistId}
						artistName={artistName}
						playlistMode={playlistType === PlaylistType.PLAYLIST ? true : false}
						artistImage={artistImage}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default observer(NewSongItem);
