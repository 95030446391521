import React, { useContext, useState } from "react";
import { createStyles, makeStyles, withStyles } from "@material-ui/core/styles";
import MoreButton from "../components/playlistcomponents/MoreButton";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import SettingsIcon from "@material-ui/icons/Settings";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Box from "@material-ui/core/Box/Box";
import { useHistory } from "react-router-dom";
import TokenManager from "../../../core/auth/TokenManager";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "rgba(255, 255, 255, 0.75)",
    background: "rgba(16, 53, 77, 0.54",
    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "rgba(255, 255, 255, 0.75)",
      },
    },
    "&::hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
}))(MenuItem);

const useStyles = makeStyles((theme) =>
  createStyles({
    contentContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "#e0e0e0",
      color: "black",
    },
    actions: {
      width: "100%",
      display: "flex",
      justifyContent: "space-around",
    },
    button: { color: "blue" },
    icon: { fill: "rgba(255,255,255,0.5" },
    text: { color: "black" },
  })
);

const ProfileSettingsButton = () => {
  const history = useHistory();
  const manager = useContext(TokenManager);

  const handleConfirmLogOut = () => {
    manager.clearToken();

    history.push("/login");
  };

  const [open, setOpen] = useState(false);

  const handleLogOut = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const classes = useStyles();

  return (
    <>
      <MoreButton dropDownIcon={<SettingsIcon className={classes.icon} />}>
        <StyledMenuItem onClick={handleLogOut}>
          <ListItemText primary="Log Out" />
        </StyledMenuItem>
      </MoreButton>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box className={classes.contentContainer}>
          <DialogTitle id="alert-dialog-title">{"Log Out"}</DialogTitle>

          <DialogContent>
            <DialogContentText
              className={classes.text}
              id="alert-dialog-description"
            >
              Are you sure you want to log out?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button onClick={handleClose} className={classes.button}>
              Cancel
            </Button>
            <Button onClick={handleConfirmLogOut} className={classes.button}>
              Log Out
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
};

export default ProfileSettingsButton;
