import gql from "graphql-tag";

const ADD_SONGS_TO_USER_LIKES = gql`
  mutation($id: String!) {
    AddSongToUserLikes: addSongToUserLikes(id: $id) {
      _id
      likedSongs {
        _id
      }
    }
  }
`;

export interface ADD_SONGS_TO_USER_LIKESResonse {
  id: any;
}

export interface ADD_SONGS_TO_USER_LIKESVariables {
  id: string;
}

export default ADD_SONGS_TO_USER_LIKES;
