import gql from "graphql-tag";

const GET_TOP_ALBUMS_BY_GENRE = gql`
	query ($genre: String) {
		albums: getTopAlbumsByGenre(genre: $genre) {
			title
			disabled
			releaseDate
			primaryArtist {
				displayName
				_id
			}
			imageUrl
			_id
		}
	}
`;

export interface GET_TOP_ALBUMS_BY_GENREResponse {
	albums?: NewAlbum[];
}

export interface GET_TOP_ALBUMS_BY_GENREVariables {
	genre: String;
}

export default GET_TOP_ALBUMS_BY_GENRE;
