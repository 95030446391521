import usePlayer from "context/PlayerContext";
import React, { FC } from "react";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";

import { makeStyles, Theme } from "@material-ui/core/styles";
const useStyles = makeStyles<Theme>((theme) => ({
  icon: {
    fill: "rgba(255,255,255,0.75)",
  },
  mute: {
    fill: "rgba(255,255,255,0.25)",
  },
}));

interface IVolumeButtonProps {}

const VolumeButton: FC<IVolumeButtonProps> = (props) => {
  const player = usePlayer();

  const classes = useStyles();

  if (player.volume > 0.5) {
    return <VolumeUpIcon className={classes.icon} />;
  }

  if (player.volume <= 0.5 && player.volume !== 0) {
    return <VolumeDownIcon className={classes.icon} />;
  }

  return <VolumeOffIcon className={classes.mute} />;
};

export default VolumeButton;
