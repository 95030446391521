import React, { ChangeEvent, FC, useContext } from "react";
import {
  Box,
  Button,
  createStyles,
  FormControl,
  FormControlLabel,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { observer } from "mobx-react-lite";
import ReportFormContext from "stores/ReportFormStore";

interface DetailedReportModalProps {
  handleClose?: () => void;
  handleSubmit?: () => void;
}

const DetailedReportModal: FC<DetailedReportModalProps> = observer(
  ({ handleClose, handleSubmit }) => {
    const styles = useStyles()();
    const store = useContext(ReportFormContext);
    const handleChange = (newOption: ChangeEvent<HTMLInputElement>) => {
      store.setOption((newOption.target as HTMLInputElement).value);
    };

    const handleTextInput = (
      newComment: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      store.setOtherComment(
        (newComment.target as HTMLInputElement | HTMLTextAreaElement).value
      );
    };

    return (
      <Box className={styles.container}>
        <Box className={styles.closeButtonContainer}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box>
          <Typography variant="h4">Why are you reporting this item?</Typography>
          <Typography>
            Giving us a reason why you are reporting this item can help us
            ensure this won't happen again.
          </Typography>
        </Box>

        <Box className={styles.optionsContainer}>
          <FormControl>
            <RadioGroup
              value={store.option}
              onChange={(option) => handleChange(option)}
            >
              <FormControlLabel value="Spam" control={<Radio />} label="Spam" />
              <FormControlLabel
                value="Abusive or Violent Content"
                control={<Radio />}
                label="Abusive or Violent Content"
              />
              <FormControlLabel
                value="Sexually Explicit Content"
                control={<Radio />}
                label="Sexually Explicit Content"
              />
              <FormControlLabel
                value="Other"
                control={<Radio />}
                label="Other"
              />

              {store.option === "Other" && (
                <TextField
                  variant="outlined"
                  rows={3}
                  multiline
                  placeholder="Please explain"
                  className={styles.textfield}
                  value={store.otherComment}
                  onChange={(text) => handleTextInput(text)}
                />
              )}
            </RadioGroup>
          </FormControl>
        </Box>
        <Button variant="contained" onClick={handleSubmit}>
          Report
        </Button>
      </Box>
    );
  }
);

const useStyles = () =>
  makeStyles(() =>
    createStyles({
      closeButtonContainer: {
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center",
        display: "flex",
        paddingRight: 10,
      },
      container: {
        width: "100%",
        height: "100%",
        paddingTop: 20,
        paddingBottom: 20,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      },
      optionsContainer: {
        marginTop: 10,
        height: 300,
        paddingLeft: 75,
        width: "100%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
      textfield: {
        width: 400,
      },
    })
  );

export default DetailedReportModal;
