import React, { Children, Component, FunctionComponent } from "react";
import { findDOMNode } from "react-dom";
import debouncer from "lodash.debounce";
import throttler from "lodash.throttle";
import parentScroll from "../components/utils/parentScroll";
import inViewport from "../components/utils/inViewport";

const add = window.addEventListener;
const remove = window.removeEventListener;

interface Props {
  className?: string;
  debounce?: boolean;
  height?: number | string;
  offset?: number;
  offsetBottom?: number;
  offsetHorizontal?: number;
  offsetLeft?: number;
  offsetRight?: number;
  offsetTop?: number;
  offsetVertical?: number;
  threshold?: number;
  throttle?: number;
  width?: number | string;
  onContentVisible?: () => void;
  elementType?: string | FunctionComponent;
  once?: boolean;
}

interface State {
  visible?: boolean;
}

export default class DualLazy extends Component<Props, State> {
  constructor({
    elementType = "div",
    debounce = true,
    offset = 0,
    offsetBottom = 0,
    offsetHorizontal = 0,
    offsetLeft = 0,
    offsetRight = 0,
    offsetTop = 0,
    offsetVertical = 0,
    throttle = 250,
    once = false,
    ...props
  }: Props) {
    super({
      elementType,
      debounce,
      offset,
      offsetBottom,
      offsetHorizontal,
      offsetLeft,
      offsetRight,
      offsetTop,
      offsetVertical,
      throttle,
      once,
      ...props,
    });

    this.lazyLoadHandler = this.lazyLoadHandler.bind(this);

    if (throttle > 0) {
      if (debounce) {
        this.lazyLoadHandler = debouncer(this.lazyLoadHandler, throttle);
      } else {
        this.lazyLoadHandler = throttler(this.lazyLoadHandler, throttle);
      }
    }

    this.state = { visible: false };
  }

  componentDidMount() {
    (this as any)._mounted = true;
    const eventNode = this.getEventNode();

    this.lazyLoadHandler();

    if ((this.lazyLoadHandler as any).flush) {
      (this.lazyLoadHandler as any).flush();
    }

    add("resize", this.lazyLoadHandler);

    eventNode.addEventListener("scroll", this.lazyLoadHandler);

    if (eventNode !== window) add("scroll", this.lazyLoadHandler);
  }

  componentDidUpdate() {
    if (!this.state.visible) {
      this.lazyLoadHandler();
    }
  }

  shouldComponentUpdate(_nextProps: Props, nextState: State) {
    return (
      nextState.visible !== this.state.visible || _nextProps !== this.props
    );
  }

  componentWillUnmount() {
    (this as any)._mounted = false;
    if ((this.lazyLoadHandler as any).cancel) {
      (this.lazyLoadHandler as any).cancel();
    }

    this.detachListeners();
  }

  getEventNode() {
    return parentScroll(findDOMNode(this as any));
  }

  getOffset() {
    const {
      offset = 0,
      offsetBottom = 0,
      offsetHorizontal = 0,
      offsetLeft = 0,
      offsetRight = 0,
      offsetTop = 0,
      offsetVertical = 0,
      threshold,
    } = this.props;

    const _offsetAll = threshold || offset;
    const _offsetVertical = offsetVertical || _offsetAll;
    const _offsetHorizontal = offsetHorizontal || _offsetAll;

    return {
      top: offsetTop || _offsetVertical,
      bottom: offsetBottom || _offsetVertical,
      left: offsetLeft || _offsetHorizontal,
      right: offsetRight || _offsetHorizontal,
    };
  }

  lazyLoadHandler() {
    if (!(this as any)._mounted) {
      return;
    }
    const offset = this.getOffset();
    const node = findDOMNode(this);
    const eventNode = this.getEventNode();

    if (inViewport(node, eventNode, offset)) {
      const { onContentVisible } = this.props;
      this.setState({ visible: true }, () => {
        if (onContentVisible) {
          onContentVisible();
        }
      });

      if (this.props.once) {
        this.detachListeners();
      }
    } else {
      if (this.props.once) {
        return;
      }

      this.setState({ visible: false });
    }
  }

  detachListeners() {
    const eventNode = this.getEventNode();

    remove("resize", this.lazyLoadHandler);

    eventNode.removeEventListener("scroll", this.lazyLoadHandler);

    if (eventNode !== window) remove("scroll", this.lazyLoadHandler);
  }

  render() {
    const { children, className, height, width } = this.props;
    const { visible } = this.state;

    const elStyles = { height, width };
    const elClasses =
      "LazyLoad" +
      (visible ? " is-visible" : "") +
      (className ? ` ${className}` : "");

    return React.createElement(
      this.props.elementType || "div",
      {
        className: elClasses,
        style: elStyles,
      } as any,
      visible && Children.only(children)
    );
  }
}
