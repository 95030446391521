import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AccountBalance from "containers/AccountBalance";
import React, { FC, useMemo } from "react";
import DollarValue from "./@ui/DollarValue";
import { makeStyles, Theme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Chart from "./@ui/Chart";
import getTotalMonthlyCosts from "jobs/getTotalMonthlyCosts";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.03)",
    borderRadius: "14px",
    display: "flex",
    maxWidth: "600px",
    maxHeight: "300px",
    minHeight: "200px",
    marginBottom: "20px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginTop: "35px",
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "20px 15px",
    backgroundColor: "rgba(196, 196, 196, 0.08)",
    width: "-webkit-fill-available",
  },
  loadingPlayChart: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    top: 0,
  },
  chart: {
    height: "80%",
    width: "100%",
    marginTop: "20px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  spinner: { marginTop: "10px" },
  accountBalance: {
    color: "white",
    fontWeight: "bold",
    marginBottom: "10px",
  },
  chartContainer: { width: "60%", maxWidth: "300px", minHeight: "200px" },
  chartCaption: { margin: "20px 5% 20px 0px" },
  songCharges: { color: "#FD4060", fontWeight: "bold" },
  cardPayments: { color: "#56C2BC", fontWeight: "bold" },
}));

interface IAccountBalanceChartProps {
  transactions?: Transaction[];
  loading?: boolean;
}

const AccountBalanceChart: FC<IAccountBalanceChartProps> = ({
  transactions,
  loading = false,
}) => {
  const sortedTransactionsByType = useMemo(() => {
    if (!transactions) {
      return;
    }

    const songData = transactions.filter(
      (t) => t.chargeType === "COST_OF_PLAY"
    );

    const cardPaymentData = transactions.filter(
      (t) => t.chargeType === "CARD_PAYMENT"
    );



    return {
      songTransactions: songData,
      cardTransactions: cardPaymentData,
    };
  }, [transactions]);

  const songData = sortedTransactionsByType
    ? sortedTransactionsByType.songTransactions
    : undefined;

  const totalCardPaymentsThisMonth = sortedTransactionsByType
    ? getTotalMonthlyCosts(sortedTransactionsByType.cardTransactions)
    : 0;

  const totalSongPaymentsThisMonth = sortedTransactionsByType
    ? getTotalMonthlyCosts(sortedTransactionsByType.songTransactions)
    : 0;


  const classes = useStyles();

  return (
    <div className={classes.mainContainer}>
      <div className={classes.infoContainer}>
        <Typography>Current Balance</Typography>
        <AccountBalance variant="h2" style={classes.accountBalance} />
        <Typography gutterBottom>This Month</Typography>
        <Container>
          <Typography variant="body2">Card Payments:</Typography>

          <Typography
            gutterBottom
            variant="subtitle1"
            className={classes.cardPayments}
          >
            {loading ? (
              <SkeletonTheme color="#1a2e3e" highlightColor="#22374a">
                <Skeleton />
              </SkeletonTheme>
            ) : (
              <DollarValue value={totalCardPaymentsThisMonth} />
            )}
          </Typography>

          <Typography variant="body2">Song Charges:</Typography>

          <Typography variant="subtitle1" className={classes.songCharges}>
            {loading ? (
              <SkeletonTheme color="#1a2e3e" highlightColor="#22374a">
                <Skeleton />
              </SkeletonTheme>
            ) : (
              <DollarValue value={totalSongPaymentsThisMonth} />
            )}
          </Typography>
        </Container>
      </div>

      <div className={classes.chartContainer}>
        <Typography
          className={classes.chartCaption}
          gutterBottom
          align="right"
          variant="body2"
        >
          Listening Activity:
        </Typography>

        <div className={classes.chart}>
          <Chart plays={loading ? undefined : songData} />
          {(loading || !songData || songData.length < 1) && (
            <div className={classes.loadingPlayChart}>
              <Typography
                align="center"
                variant="body2"
                gutterBottom
                color="secondary"
              >
                {loading
                  ? "Give us a second while we load in your plays."
                  : `Start listening to music and we'll keep track of your listening activity this month.`}
              </Typography>
              {loading && (
                <CircularProgress className={classes.spinner} color="primary" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountBalanceChart;
