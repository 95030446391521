import Hidden from "@material-ui/core/Hidden/Hidden";

import LOGIN_USER, {
  LOGIN_USERResponse,
} from "core/graphql/new/mutations/Login";
import React, { useContext, useEffect, useState } from "react";
import LoginDesktop from "./LoginDesktop";
import LoginMobile from "./LoginMobile";
import TokenManager from "../../core/auth/TokenManager";
// import { useHistory } from "react-router-dom";
import useUnauthenticatedClient from "jobs/UnathenicatedClient";
import { observer } from "mobx-react-lite";
import getEnvironmentVariable from "../../core/utils/getEnvironmentVariable";
import { toJS } from "mobx";

interface LoginParams {
  email: string;
  password: string;
}

const accountURL = getEnvironmentVariable("REACT_APP_BENE_URI");

const LoginResponsive = () => {
  const client = useUnauthenticatedClient();

  const redirectUrl = new URLSearchParams(window.location.search).get(
    "redirectUrl"
  );

 

  const manager = useContext(TokenManager);



  const [loading, setLoading] = useState(false);

  const [failed, setFailed] = useState(false);

  const [token, setToken] = useState<string>();

  const [errorMessage, setErrorMessage] = useState("");







  const handleSubmit = async (email: string, password: string) => {
    setLoading(true);
    try {
      const { data } = await client.mutate<LOGIN_USERResponse>({
        mutation: LOGIN_USER,
        variables: {
          email: email,
          password: password,
        },
      });

      if (data?.login) {
        setToken(data?.login);
      }

      return;
    } catch (err) {
      console.log(err);

      setLoading(false);
      setFailed(true);
      setErrorMessage(err?.graphQLErrors?.[0]?.message);
    }
  };

  const handleLogin = async (token?: string) => {
    if (token) {
      setLoading(false);

      console.log(token);

      manager.storeToken(token);

      manager.setToken(token);

      console.log(toJS(manager.decodedToken));

      if (redirectUrl) {
        window.location.href = redirectUrl;
        return;
      }

      if (
        manager.decodedToken?.isArtist ||
        manager.decodedToken?.isBeneficiary
      ) 
      {
        window.location.href = accountURL;
        return;
      }

      window.location.pathname = "/";
    }

    setLoading(false);

    return;
  };

  useEffect(() => {
    handleLogin(token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      <meta
        name="viewport"
        content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
      ></meta>
      <Hidden mdUp={true}>
        <LoginMobile handleSubmit={handleSubmit} />
      </Hidden>

      <Hidden smDown={true}>
        <LoginDesktop
          handleSubmit={handleSubmit}
          errormessage={errorMessage}
          loading={loading}
          failed={failed}
        />
      </Hidden>
    </>
  );
};

export default observer(LoginResponsive);
