import gql from "graphql-tag";

const DELETE_PLAYLIST = gql`
  mutation($playlistId: String!) {
    deletePlaylist(playlistId: $playlistId)
  }
`;

export interface DELETE_PLAYLISTVariables {
  playlistId: string;
}

export default DELETE_PLAYLIST;
