import gql from "graphql-tag";

const GET_TOP_SONGS_BY_GENRE = gql`
  query($genre: String) {
    songs: getTopSongsByGenre(genre: $genre) {
      name
      album {
        disabled
        title
        _id
      }
      image
      disabled
      costPerPlay
      primaryArtist {
        displayName
        _id
        image
      }
      _id
    }
  }
`;

export interface GET_TOP_SONGS_BY_GENREResponse {
  songs?: NewSong[];
}

export interface GET_TOP_SONGS_BY_GENREVariables {
  genre: String;
}

export default GET_TOP_SONGS_BY_GENRE;
