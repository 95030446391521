import React, { useContext } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Alert, AlertTitle } from "@material-ui/lab";
import EventMessageContext from "stores/EventMessage";
import { observer } from "mobx-react-lite";
import { Typography } from "@material-ui/core";

const useStyles = (severityType?: string) =>
  makeStyles((theme) =>
    createStyles({
      root: {
        width: "100%",
        "& > * + *": {
          marginTop: theme.spacing(2),
        },
      },
      alert: {
        backgroundColor: severityType === "error" ? "#662828" : "#607d8b",
        "& .MuiAlert-message": {
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        },
        "& .MuiAlert-icon": {
          color: "white",
        },
        "& .MuiAlert-action": {
          color: "white",
        },
      },
      message: {
        color: "#ffffff8a",
        fontWeight: 500,
        marginLeft: "10px",
      },
      title: {
        color: "white",
        fontWeight: 900,
        fontSize: "17px",
        marginBottom: "0px",
      },
    })
  );

const DescriptionAlert = () => {
  const eventManager = useContext(EventMessageContext);
  const classes = useStyles(eventManager.severityType)();

  return (
    <div className={classes.root}>
      <Alert
        onClose={eventManager.clearEvent}
        className={classes.alert}
        severity={
          eventManager.severityType as
            | "success"
            | "error"
            | "info"
            | "warning"
            | undefined
        }
      >
        <AlertTitle className={classes.title}>{eventManager.title}</AlertTitle>
        <Typography className={classes.message}>
          {eventManager.message}
        </Typography>
      </Alert>
    </div>
  );
};

export default observer(DescriptionAlert);
