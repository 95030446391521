import React, { FC } from "react";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import { useQuery } from "@apollo/react-hooks";
import HorizontalList from "components/@ui/HorizontalList";
import AlbumContainer from "containers/AlbumContainer";
import ProfileSettingsButton from "pages/Desktop/components/ProfileSettingsButton";
import Hidden from "@material-ui/core/Hidden/Hidden";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { useHistory } from "react-router-dom";
import AccountBalance from "containers/AccountBalance";
import GET_FEATURD_ALBUMS, {
	GET_FEATURD_ALBUMSResponse,
} from "../../core/graphql/new/queries/getFeaturedAlbums";
import GET_TOP_ALBUMS, {
	GET_TOP_ALBUMSResponse,
	GET_TOP_ALBUMSVariables,
} from "core/graphql/new/queries/getTopAlbums";
import GET_TOP_GENRES, {
	GET_TOP_GENRESResponse,
} from "core/graphql/new/queries/getTopGenres";
import GenreItem from "components/@ui/GenreItem";
import GET_RECENT_ALBUMS, {
	GET_RECENT_ALBUMSResponse,
} from "core/graphql/new/queries/getRecentlyUploadedAlbums";
import { DateTime } from "luxon";

interface DashboardMobileProps { }

const useStyles = makeStyles((theme) =>
	createStyles({
		logoutButton: {
			width: "100%",
			display: "flex",
			justifyContent: "flex-end",
			paddingRight: "16px",
			paddingTop: "16px",
		},

		activeSearchBar: {
			height: "30px",
			width: "80%",
			margin: "20px 5px 15px 0px",
			borderRadius: "25px",
			background: "rgb(255 255 255 / 0.24)",
			border: "none",
			paddingLeft: "20px",
			color: "#c1c1c1",
			"&:focus": {
				outline: "none",
				background: "#ffffff40",
			},
			"&::-webkit-input-placeholder": {
				color: "rgb(255 255 255 / 0.3)",
			},
		},
		spacer: {
			marginLeft: "15px",
		},
		searchBox: {
			display: "flex",
			alignItems: "center",
			justifyContent: "space-between",
			paddingRight: "50px",
		},
		searchForm: {
			display: "flex",
			alignItems: "center",
			width: "80%",
		},
		mobileHeader: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
		},
		starFarmFont: {
			fontFamily: "simplifica",
			letterSpacing: 6,
			fontSize: 44,
			marginBottom: 20,
		},
		searchTitle: { marginLeft: "20px", marginRight: "20px" },
	})
);

const DashboardPage: FC<DashboardMobileProps> = () => {
	const classes = useStyles();

	const { data: recentAlbums } =
		useQuery<GET_RECENT_ALBUMSResponse>(GET_RECENT_ALBUMS);

	const { data: featuredData } =
		useQuery<GET_FEATURD_ALBUMSResponse>(GET_FEATURD_ALBUMS);

	const { data: topAlbums } = useQuery<
		GET_TOP_ALBUMSResponse,
		GET_TOP_ALBUMSVariables
	>(GET_TOP_ALBUMS, { variables: { limit: 15 } });

	const { data: topGenreData } =
		useQuery<GET_TOP_GENRESResponse>(GET_TOP_GENRES);

	const history = useHistory();

	const handleSearchSelection = () => {
		history.push("/search");
	};




	return (
		<>
			<Hidden mdUp={true}>
				<Box className={classes.logoutButton}>
					<Box className={classes.spacer} />
					<ProfileSettingsButton />
				</Box>
			</Hidden>

			<Hidden smDown={true}>
				<Box className={classes.searchBox}>
					<form className={classes.searchForm}>
						<Typography className={classes.searchTitle} variant="h3">
							Search
						</Typography>

						<input
							onClick={handleSearchSelection}
							placeholder="Songs, Artists, Albums"
							className={classes.activeSearchBar}
						></input>
					</form>
					<AccountBalance />
				</Box>
			</Hidden>

			<Hidden mdUp={true}>
				<Box pt={4} className={classes.mobileHeader}>
					<Typography
						align="center"
						variant="h1"
						className={classes.starFarmFont}
					>
						StarFarm
					</Typography>
					<AccountBalance />
				</Box>
			</Hidden>

			<Box px={2} mb={2} mt={8}>
				<Typography variant="h4">Featured</Typography>
			</Box>

			<HorizontalList id="recent-albums">

				{featuredData?.featuredAlbums?.map((album) => (
					<AlbumContainer
						key={album._id}
						image={album.imageUrl}
						title={album.title}
						artistName={album.primaryArtist?.displayName}
						artistId={album.primaryArtist?._id}
						albumId={album._id}
						isSingle={album.isSingle}
						tertiaryTitle={
							album.releaseDate
								? DateTime.fromISO(album.releaseDate!).toFormat("yyyy")
								: "2021"
						}
					/>
				))}
			</HorizontalList>

			<Box px={2} mb={2} mt={8}>
				<Typography variant="h4">Trending Albums</Typography>
			</Box>

			<HorizontalList id="for-you">
				{topAlbums?.topAlbums?.map((album) => (
					<AlbumContainer
						key={album._id}
						image={album.imageUrl}
						title={album.title}
						artistName={album.primaryArtist?.displayName}
						artistId={album.primaryArtist?._id}
						albumId={album._id}
						isSingle={album.isSingle}
						tertiaryTitle={
							album.releaseDate
								? DateTime.fromISO(album.releaseDate!).toFormat("yyyy")
								: "2021"
						}
					/>
				))}
			</HorizontalList>

			<Box px={2} mb={2} mt={8}>
				<Typography variant="h4">Top Genres</Typography>
			</Box>

			<HorizontalList id="top-genres">
				{topGenreData?.genres?.map((genre, index) => (
					<GenreItem key={genre._id} genre={genre.name} colorIndex={index} />
				))}
			</HorizontalList>

			<Box px={2} mb={2} mt={8}>
				<Typography variant="h4">Recently Uploaded</Typography>
			</Box>

			<HorizontalList id="featured-albums">
				{recentAlbums?.albums.map((album) => (
					<AlbumContainer
						key={album._id}
						image={album.imageUrl}
						title={album.title}
						artistName={album.primaryArtist?.displayName}
						artistId={album.primaryArtist?._id}
						albumId={album._id}
						isSingle={album.isSingle}
						tertiaryTitle={
							album.releaseDate
								? DateTime.fromISO(album.releaseDate!).toFormat("yyyy")
								: "2021"
						}
					/>
				))}
			</HorizontalList>

			<Box pb={18} />
		</>
	);
};

export default DashboardPage;
