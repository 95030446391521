import gql from "graphql-tag";

const GET_FEATURD_ALBUMS = gql`
	query {
		featuredAlbums: findAlbumMany(sort: CREATEDAT_DESC, filter:{isFeatured: true}) {
			_id
			title
			disabled
			primaryArtist {
				_id
				displayName
			}
			isSingle
			imageUrl
			releaseDate
		}
	}
`;

export interface GET_FEATURD_ALBUMSResponse {
	featuredAlbums?: NewAlbum[];
}

export interface GET_FEATURD_ALBUMSVariables { }

export default GET_FEATURD_ALBUMS;
