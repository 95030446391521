import gql from "graphql-tag";

const GET_USER_PAYMENT_METHOD = gql`
  query {
    paymentMethod: getUserPaymentMethod
  }
`;

export interface GET_USER_PAYMENT_METHODResponse {
  paymentMethod: string;
}

export default GET_USER_PAYMENT_METHOD;
