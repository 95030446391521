import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import PaymentAmountSelect from "components/@ui/PaymentAmountSelect";
import React, { FC, useState } from "react";
import Box from "@material-ui/core/Box/Box";
import Container from "@material-ui/core/Container/Container";
import StarfarmLogo from "components/@ui/StarfarmLogo";
import Hidden from "@material-ui/core/Hidden/Hidden";
import { makeStyles, Theme } from "@material-ui/core/styles";
import ResponsivePaymentInfo from "components/ResponsivePaymentInfo";
import { useQuery } from "@apollo/react-hooks";

import GET_USER_PAYMENT_METHOD, {
  GET_USER_PAYMENT_METHODResponse,
} from "core/graphql/new/queries/getUserPaymentMethod";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles<Theme>((theme) => ({
  mainContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    paddingTop: "25px",
  },
  contentContainer: {
    display: "flex",
    justifyContent: "space-around",
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "80%",
    },
  },

  skip: {
    padding: "15px",
    textDecoration: "underline",
    textAlign: "center",
    width: "fit-content",
    "&:hover": {
      cursor: "pointer",
    },
  },
  paymentInfo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    marginTop: "10px",
  },
}));

interface IPaymentPageProps {}

const PaymentPage: FC<IPaymentPageProps> = (props) => {
  const history = useHistory();

  const { data } = useQuery<GET_USER_PAYMENT_METHODResponse>(
    GET_USER_PAYMENT_METHOD
  );

  const classes = useStyles();

  const [paymentPrice, setPaymentPrice] = useState<number | undefined>(
    undefined
  );

  return (
    <Container className={classes.mainContainer}>
      <Grid container className={classes.contentContainer}>
        <Grid
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          item
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Hidden mdUp={true}>
            <StarfarmLogo size={75} />
          </Hidden>

          <Typography variant="h3">Pay per Play Streaming</Typography>

          <div>
            <Typography
              style={{ color: "#FD4060", marginBottom: "20px" }}
              variant="subtitle1"
            >
              Only pay for the amount of music you listen to.
            </Typography>

            <Typography variant="subtitle2">
              We allow artists to set the prices of their songs for each play,
              with each song averaging the price of $0.01 per play. <br />
              <br />
              100 plays means 100 cents. Simple as that. Transfer money to your
              StarFarm balance and use that to pay for each play.
            </Typography>
          </div>

          <Typography variant="subtitle1">
            {data?.paymentMethod
              ? "Deposit funds into your account and get back to the music:"
              : "Start listening to music with your first deposit:"}
          </Typography>

          <Box
            display="flex"
            alignItems="flex-end"
            justifyContent="space-between"
            mb={1}
            flexWrap="wrap"
            paddingTop="10px"
          >
            <PaymentAmountSelect
              selectPrice={setPaymentPrice}
              active={paymentPrice === 10 ? true : false}
              price={10}
              banner="Most Common"
            />

            <PaymentAmountSelect
              selectPrice={setPaymentPrice}
              active={paymentPrice === 20 ? true : false}
              price={20}
            />

            <PaymentAmountSelect
              selectPrice={setPaymentPrice}
              active={paymentPrice === 50 ? true : false}
              price={50}
            />

            <PaymentAmountSelect
              selectPrice={setPaymentPrice}
              active={paymentPrice === 100 ? true : false}
              price={100}
            />
          </Box>
        </Grid>
        <Grid
          xl={5}
          lg={5}
          md={5}
          sm={5}
          xs={12}
          className={classes.paymentInfo}
          item
        >
          <ResponsivePaymentInfo paymentAmount={paymentPrice} />

          <Box width="100%" display="flex" justifyContent="center">
            <Typography
              onClick={() => history.push("/")}
              className={classes.skip}
              variant="body1"
            >
              {data?.paymentMethod ? "Close" : "Skip for now"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PaymentPage;
