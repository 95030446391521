import React, { ChangeEvent, FC, FormEvent, useEffect, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import SEARCH_BY_TERM, {
	SEARCH_BY_TERMResponse,
	SEARCH_BY_TERMVariables,
} from "core/graphql/new/queries/searchByTerm";
import { useLazyQuery } from "@apollo/react-hooks/lib/useLazyQuery";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import Typography from "@material-ui/core/Typography/Typography";
import Box from "@material-ui/core/Box/Box";

import AlbumContainer from "containers/AlbumContainer";
import ArtistContainer from "containers/ArtistContainer";
import HorizontalList from "components/@ui/HorizontalList";
import SongList from "containers/SongList";
import { PlaylistType } from "models/Player";
import { DateTime } from "luxon";

const useStyles = () =>
	makeStyles((theme) =>
		createStyles({
			activeSearchBar: {
				height: "30px",
				width: "60%",
				margin: "20px 5px 15px 0px",
				borderRadius: "25px",
				background: "rgb(255 255 255 / 0.24)",
				border: "none",
				paddingLeft: "20px",
				color: "#c1c1c1",
				"&:focus": {
					outline: "none",
					background: "#ffffff40",
				},
				"&::-webkit-input-placeholder": {
					color: "rgb(255 255 255 / 0.3)",
				},
			},
		})
	);

interface IResponsiveDashboardProps {}

const SearchResponsive: FC<IResponsiveDashboardProps> = (props) => {
	const classes = useStyles()();
	const location = useLocation();
	const history = useHistory();
	const queryString = location.search;
	const searchTerm = queryString.slice(2);
	const [inputvalue, setInputValue] = useState("");

	const [search, { data }] = useLazyQuery<
		SEARCH_BY_TERMResponse,
		SEARCH_BY_TERMVariables
	>(SEARCH_BY_TERM);

	const handleSearch = async () => {
		try {
			await search({
				variables: {
					term: searchTerm,
				},
			});
			return;
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		setInputValue(searchTerm);

		handleSearch();

		return;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	const handleChange = (e: ChangeEvent<any>) => {
		setInputValue(e.target.value);
	};

	const handleSubmitSearch = (e: FormEvent<any>) => {
		e.preventDefault();

		history.push(`/search?=${inputvalue}`);
	};

	return (
		<Box pb={20}>
			<form
				onSubmit={handleSubmitSearch}
				style={{ display: "flex", alignItems: "center" }}
			>
				<Typography
					style={{ marginLeft: "20px", marginRight: "20px" }}
					variant="h3"
				>
					Search
				</Typography>

				<input
					type="text"
					value={inputvalue}
					onChange={handleChange}
					placeholder="Songs, Artists, Albums"
					className={classes.activeSearchBar}
					autoFocus
				></input>
			</form>

			{!!data?.searchResponse.artists.length && (
				<Box my={6}>
					<Typography
						style={{ paddingLeft: "24px" }}
						gutterBottom={true}
						color="textSecondary"
						variant="h4"
					>
						Artists
					</Typography>

					<HorizontalList id="Searched Artists">
						{data?.searchResponse.artists.map((artist) => (
							<ArtistContainer
								key={artist._id}
								image={artist.image}
								title={artist.displayName}
								artistId={artist._id}
							/>
						))}
					</HorizontalList>
				</Box>
			)}

			{!!data?.searchResponse.albums.length && (
				<>
					<Box width="100%" mb={6}>
						<Typography
							style={{ paddingLeft: "24px" }}
							variant="h4"
							color="textSecondary"
							gutterBottom={true}
						>
							Albums
						</Typography>

						<HorizontalList id="PlayLists">
							{data?.searchResponse.albums.map((album) => (
								<AlbumContainer
									key={album._id}
									image={album.imageUrl}
									title={album.title}
									artistName={album.primaryArtist?.displayName}
									artistId={album.primaryArtist?._id}
									albumId={album._id}
									isSingle={album.isSingle}
									tertiaryTitle={
										album.releaseDate
											? DateTime.fromISO(album.releaseDate!).toFormat("yyyy")
											: "2021"
									}
								/>
							))}
						</HorizontalList>
					</Box>
				</>
			)}

			{!!data?.searchResponse.songs.length && (
				<Box px={3}>
					<Typography color="textSecondary" variant="h4" gutterBottom={true}>
						Songs
					</Typography>
					<SongList
						songs={data.searchResponse.songs}
						playlistType={PlaylistType.SEARCH}
					/>
				</Box>
			)}
		</Box>
	);
};

export default SearchResponsive;
