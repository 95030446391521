import gql from "graphql-tag";

const GET_TOP_ALBUMS = gql`
	query ($limit: Int) {
		topAlbums: getTopAlbums(limit: $limit) {
			_id
			title
			disabled
			primaryArtist {
				_id
				displayName
			}
			isSingle
			imageUrl
			releaseDate
		}
	}
`;

export interface GET_TOP_ALBUMSResponse {
	topAlbums?: NewAlbum[];
}

export interface GET_TOP_ALBUMSVariables {
	limit: number;
}

export default GET_TOP_ALBUMS;
