import React, { FC } from "react";
import { Box } from "@material-ui/core";
import { IconButton } from "@material-ui/core";

import PlayButton from "./PlayButton";
import ShuffleButton from "./ShuffleButton";

import SkipPreviousIcon from "@material-ui/icons/SkipPrevious";
import SkipNextButton from "./skipNextButton";
import { observer } from "mobx-react-lite";
import usePlayer from "context/PlayerContext";
import RepeatButton from "./RepeatButton";
import { makeStyles, Theme } from "@material-ui/core/styles";

interface IModalControlsProps {
  horizontal?: boolean;
}

interface IThemeProps {
  horizontal: boolean;
}

const useStyles = makeStyles<Theme, IThemeProps>((theme) => ({
  iconButton: {
    padding: "5px",
  },
}));

const ModalControls: FC<IModalControlsProps> = ({ horizontal = false }) => {
  const player = usePlayer();
        player.setVolume(player.volume)
        

  const classes = useStyles({ horizontal });

  const ShuffleClick = () => {
    player.setShuffle(!player.shuffled);
    player.setVolume(player.volume)
  };

  const repeatClick = () => {
    player.repeatClick();
    player.setVolume(player.volume)
  };

  const handlePreviousSong = () => {
    player.previous();
    player.setVolume(player.volume)
  };

  const handleNextSong = () => {
    player.next();
    player.setVolume(player.volume)
   
  };

  const handleSongPlay = () => {
    if (player.playing) {
      player.pause();
      player.setVolume(player.volume)
      return;
    }

    player.play();
    player.setVolume(player.volume)
  };

  const buttonStyle = {
    background: "white",
    width: "2.5vh",
    height: "2.5vh",
  };

  return (
    <Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-around"
        px={3}
        alignItems="center"
      >
        {horizontal && (
          <IconButton className={classes.iconButton} onClick={ShuffleClick}>
            <ShuffleButton small shuffle={player.shuffled} />
          </IconButton>
        )}

        <IconButton className={classes.iconButton} onClick={handlePreviousSong}>
          <SkipPreviousIcon
            style={{ fontSize: horizontal ? "25px" : "4vh", fill: "#3beafb" }}
          />
        </IconButton>

        <IconButton
          className={classes.iconButton}
          style={buttonStyle}
          onClick={handleSongPlay}
        >
          <PlayButton small songPlay={player.playing} />
        </IconButton>

        <IconButton className={classes.iconButton} onClick={handleNextSong}>
          <SkipNextButton small />
        </IconButton>

        {horizontal && (
          <IconButton className={classes.iconButton} onClick={repeatClick}>
            <RepeatButton small />
          </IconButton>
        )}
      </Box>

      {horizontal === false && (
        <Box width="100%" display="flex" justifyContent="space-between">
          <IconButton onClick={ShuffleClick}>
            <ShuffleButton shuffle={player.shuffled} />
          </IconButton>

          <IconButton onClick={repeatClick}>
            <RepeatButton />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default observer(ModalControls);
