import { useApolloClient, useLazyQuery } from "@apollo/react-hooks";
import GET_CURRENT_USER, {
  GET_CURRENT_USERResponse,
} from "core/graphql/new/queries/getCurrentUser";
import PlayerStore from "models/Player";
import React, { FC, createContext, useEffect } from "react";
import { useMemo } from "react";

interface IPlayerContextProviderProps { }

export const PlayerContext = createContext(new PlayerStore());

const PlayerContextProvider: FC<IPlayerContextProviderProps> = ({
  children,
}) => {
  const client = useApolloClient();

  const player = useMemo(() => {
    return new PlayerStore(client);
  }, [client]);

  const [getUser, { data }] = useLazyQuery<GET_CURRENT_USERResponse>(
    GET_CURRENT_USER
  );

  const handleSetUserPreferences = async () => {
    try {
      await getUser();
    } catch (e) {
      console.log(e.message);
    }
  };

  const setData = () => {
    player.setAutoPlay(data?.currentUser?.autoPlaySongs || false);
  };

  useEffect(() => {
    if (!data) {
      handleSetUserPreferences();
    }

    if (data) {
      setData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <PlayerContext.Provider value={player}>{children}</PlayerContext.Provider>
  );
};

export default PlayerContextProvider;
