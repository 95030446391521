import React, { FC } from "react";

import { Box, makeStyles, createStyles } from "@material-ui/core";
import studioImage from "picture/studio2.jpg";

const useStyles = (image: string) =>
  makeStyles(() =>
    createStyles({
      root: {
        position: "relative",
      },
      backgroundImage: {
        background: `url('${image}')`,
        height: "100vh",
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "cover",
        top: 0,
        left: 0,
        zIndex: -2,
        position: "fixed",
      },
      mainGradient: {
        top: 0,
        left: 0,
        position: "fixed",
        zIndex: -1,
        background: "linear-gradient(0deg, #02172a 0%, #0000003d)",
        height: "100vh",
      },
      content: {
        background:
          "linear-gradient(180deg, #0000003d 0%, #021729f0 53vh, #021729 35%)",
        minHeight: "100vh",
      },
    })
  );

interface IParallaxProps {
  image?: string;
}

const PageWithBackgroundImage: FC<IParallaxProps> = ({
  children,
  image = studioImage,
}) => {
  const classes = useStyles(image)();

  return (
    <Box className={classes.root}>
      <Box className={classes.backgroundImage} />

      <Box className={classes.mainGradient} />

      <Box className={classes.content}>{children}</Box>
    </Box>
  );
};

export default PageWithBackgroundImage;
