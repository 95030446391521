import { observable, action } from "mobx";
import { createContext } from "react";

class EventMessageManager {
  @observable
  public severityType?: string;

  @observable
  public title?: string;

  @observable
  public message?: string;

  @action
  public setEvent = (severityType: string, title: string, message: string) => {
    this.severityType = severityType;

    this.title = title;

    this.message = message;

    if (severityType !== "error") {
      setTimeout(this.clearEvent, 5000);
    }
  };

  @action
  public clearEvent = () => {
    this.severityType = undefined;
    this.message = undefined;
    this.title = undefined;
  };
}

export const EventMessageStore = new EventMessageManager();

const EventMessageContext = createContext(EventMessageStore);

export default EventMessageContext;
