import gql from "graphql-tag";

const CREATE_USER = gql`
  mutation(
    $email: String!
    $password: String!
    $isArtist: Boolean!
    $artistDisplayName: String
    $firstName: String!
    $lastName: String!
  ) {
    token: createUser(
      email: $email
      password: $password
      isArtist: $isArtist
      artistDisplayName: $artistDisplayName
      firstName: $firstName
      lastName: $lastName
    )
  }
`;

export type CREATE_USERResonse = {
  token: string;
};

export interface CREATE_USERVariables {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  isArtist: boolean;
  artistDisplayName?: string;
}

export default CREATE_USER;
