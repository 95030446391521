import {
  createMuiTheme,
  responsiveFontSizes,
  Theme,
} from "@material-ui/core/styles";

let theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#3beafb",
      main: "#3D7670",
    },
    secondary: {
      main: "#4cb6cb",
      contrastText: "#fff",
    },
    text: {
      primary: "#bdbdbd",
      secondary: "#8a8a8a",
    },
    background: {
      paper: "#082338",
    },
  },
  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 16,
      },
    },
  },

  typography: {
    h1: {
      fontSize: 44,
    },
    h2: {
      fontSize: 36,
    },
    h3: {
      fontSize: 28,
    },
    h4: {
      fontSize: 20,
    },
    h5: {
      fontSize: 12,
    },
    body1: {
      fontSize: 12,
    },
    body2: {
      fontSize: 10,
    },
    caption: {
      fontSize: 8,
    },
    subtitle2: {
      fontSize: 11,
    },
  },
} as Theme);

theme = responsiveFontSizes(theme);

export default theme;
