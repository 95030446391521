import React, { FC, useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";
import DollarValue from "components/@ui/DollarValue";
import { useMutation, useQuery } from "@apollo/react-hooks";
import GET_CURRENT_USER, {
  GET_CURRENT_USERResponse,
} from "core/graphql/new/queries/getCurrentUser";
import CurrencyInput from "react-currency-input-field";
import TIP_ARTIST, {
  TIP_ARTISTResponse,
  TIP_ARTISTVariables,
} from "core/graphql/new/mutations/tipArtist";
import { useHistory } from "react-router-dom";
import EventMessageContext from "stores/EventMessage";
import GET_ARTIST_TIPS from "core/graphql/new/queries/getArtistTips";
import TokenManager from "core/auth/TokenManager";

const useStyles = (backgroundImage?: string) =>
  makeStyles((theme) =>
    createStyles({
      input: {
        color: "rgb(255 255 255 / 0.75)",
        border: "none",
        background: "transparent",
        borderRadius: "25px",
        fontSize: "xx-large",
        width: "inherit",
        textAlign: "center",
        "&::-webkit-input-placeholder": {
          color: "rgb(255 255 255 / 0.5)",
        },
        "&:focus": {
          outline: "none",
        },
      },
      contentContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        width: "100%",
        height: "100%",
        justifyContent: "space-evenly",
        paddingTop: "10px",
        paddingBottom: "10px",
        position: "relative",
      },
      donateButton: {
        width: "80%",
        borderRadius: 100,
        background: "#3D7670",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
        [theme.breakpoints.down("xs")]: {
          marginTop: "20px",
        },
      },
      addFundsButton: {
        width: "80%",
        borderRadius: 100,
        borderColor: "white",
        backgroundColor: "#2C3E4D",
        border: "1px solid white",
        "&:hover": {
          cursor: "pointer",
        },
      },
      image: {
        backgroundImage: `url(${backgroundImage})`,

        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "250px",
        width: "250px",
        borderRadius: "50%",
        [theme.breakpoints.down("xs")]: {
          height: "200px",
          width: "200px",
        },
      },
      dialogPaper: {
        margin: 0,
        backgroundColor: "#061c2d",
        maxWidth: "700px",
        width: "80%",
        height: "100%",
        [theme.breakpoints.down("xs")]: {
          width: "100vw",
          height: "100vh",
          maxWidth: "100vw",
          minWidth: "100vw",
          maxHeight: "100vh",
          minHeight: "100vh",
        },
      },
      modalActions: {
        height: "60%",
        width: "100%",
        backgroundColor: "#2C3E4D",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        position: "absolute",
        bottom: 0,
        zIndex: -1,
      },
      modalHeader: {
        width: "100%",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "2%",
      },
      actionBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "200px",
        height: "200px",
        background: "rgba(255,255,255,0.1)",
        borderRadius: "12px",
        [theme.breakpoints.down("sm")]: {
          height: "150px",
          width: "150px",
        },
        [theme.breakpoints.down("xs")]: {
          height: "125px",
          width: "125px",
        },
      },
      button: {
        background: "#386d6a",
        width: "135px",
        borderRadius: "100px",
        // marginTop: "16px",
      },
    })
  );

interface ITipArtistModal {
  artistName?: string;
  artistId?: string;
  artistImage?: string;
  buttonStyle?: string;
  menuItem?: boolean;
}

const TipArtistModal: FC<ITipArtistModal> = ({
  artistId,
  artistImage,
  artistName,
  buttonStyle,
  menuItem = false,
}) => {
  const { data: userData } = useQuery<GET_CURRENT_USERResponse>(
    GET_CURRENT_USER
  );

  const manager = useContext(TokenManager);
  const token = manager.getToken();

  const [TipArtistModal] = useMutation<TIP_ARTISTResponse, TIP_ARTISTVariables>(
    TIP_ARTIST,
    {
      refetchQueries: [
        { query: GET_CURRENT_USER },
        { query: GET_ARTIST_TIPS, variables: { artistId, limit: 5 } },
      ],
    }
  );

  const alertManager = useContext(EventMessageContext);

  const [open, setOpen] = useState(false);
  const [tipAmount, setTipAmount] = useState<string>("");

  const handleSendTip = async () => {

    if (
      !tipAmount ||
      parseFloat(tipAmount) <= 0 ||
      (userData?.currentUser?.balanceInPennies &&
        parseFloat(tipAmount) > userData?.currentUser?.balanceInPennies / 100)
    ) {
      return;
    }

    if (tipAmount && artistId) {
      try {
        await TipArtistModal({
          variables: {
            artistId: artistId,
            amount: parseFloat(tipAmount) * 100,
          },
        });

        alertManager.setEvent(
          "success",
          "Success",
          `Sent tip to ${artistName || "artist"}`
        );

        setTipAmount("0");
      } catch (err) {
        alertManager.setEvent("error", "Error", err.graphQLErrors[0].message);
      }
    }
    setOpen(false);
  };

  const classes = useStyles(artistImage)();

  const history = useHistory();

  const handleAddFunds = () => {
    setOpen(false);
    history.push("/account");
  };

  const SendTip = () => {
    if (token === 'undefined') {
      window.location.href = "/login"
    }
    else {
      setOpen(true)
    }
  }

  return (
    <>
      {menuItem ? (
        <Typography onClick={SendTip} variant="body1">
          Tip Artist
        </Typography>
      ) : (
        <Button
          className={buttonStyle ? buttonStyle : classes.button}
          size="medium"
          onClick={SendTip}
        >
          Tip Artist
        </Button>
      )
      }

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
      >
        <Box className={classes.contentContainer}>
          <IconButton
            style={{
              position: "absolute",
              top: "5px",
              right: "10px",
              zIndex: 9999,
            }}
            onClick={() => setOpen(false)}
          >
            <CloseIcon style={{ fill: "#ffffff59", fontSize: "25px" }} />
          </IconButton>

          <Box className={classes.modalHeader}>
            <Typography variant="body1" color="textSecondary">
              Send Tip To
            </Typography>
            <Typography variant="h2">{artistName?.toUpperCase()}</Typography>
          </Box>

          <Box className={classes.image} />

          <Box className={classes.modalActions}></Box>
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-around",
              padding: "10px",
              alignItems: "center",
            }}
          >
            <Box className={classes.actionBox}>
              <Typography gutterBottom variant="h3">
                StarFarm Balance
              </Typography>
              <Typography variant="h3" color="secondary">
                <DollarValue
                  value={(userData?.currentUser?.balanceInPennies || 0) / 100}
                />
              </Typography>
            </Box>
            <Box className={classes.actionBox}>
              <CurrencyInput
                type="tele"
                value={tipAmount}
                onValueChange={(value) => setTipAmount(value || "0")}
                decimalSeparator="."
                prefix="$"
                className={classes.input}
                placeholder="$0.00"
              />
            </Box>
          </Box>

          <Grid justify="space-between" container>
            <Grid item md={6} lg={6} xs={12} sm={6}>
              <Button
                onClick={handleAddFunds}
                className={classes.addFundsButton}
              >
                Add Funds
              </Button>
            </Grid>

            <Grid item md={6} lg={6} sm={6} xs={12}>
              <Button
                disabled={
                  !tipAmount ||
                    parseFloat(tipAmount) <= 0 ||
                    (userData?.currentUser?.balanceInPennies &&
                      parseFloat(tipAmount) >
                      userData?.currentUser?.balanceInPennies / 100)
                    ? true
                    : false
                }
                onClick={handleSendTip}
                className={classes.donateButton}
              >
                Send Tip
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    </>
  );
};

export default TipArtistModal;
