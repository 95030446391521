import React, { FC, useEffect } from "react";

interface IUnauthenticatedProps { }

const Unauthenticated: FC<IUnauthenticatedProps> = (props) => {
  console.log('unauth')
  const redirectPath = window.location.search;
  useEffect(() => {
    window.location.href = redirectPath ? `/login${redirectPath}` : "/login";
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>;
};

export default Unauthenticated;
