import gql from "graphql-tag";

const ADD_SONG_TO_PLAYLIST = gql`
  mutation($songId: String, $playlistId: String) {
    addSongToPlaylist(playlistId: $playlistId, songId: $songId) {
      name
      songs {
        _id
        name
      }
    }
  }
`;

export interface ADD_SONG_TO_PLAYLISTVariables {
  songId: string;
  playlistId: string;
}

export default ADD_SONG_TO_PLAYLIST;
