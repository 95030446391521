import React from "react";

const HorizontalRule = () => {
  return (
    <hr
      style={{ border: "rgba(255,255,255,0.1) 0.5px solid", width: "100%" }}
    />
  );
};

export default HorizontalRule;
