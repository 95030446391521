import gql from "graphql-tag";

const LOGIN_USER = gql`
  mutation($email: String!, $password: String!) {
    login: login(email: $email, password: $password)
  }
`;

export interface LOGIN_USERResponse {
  login: string;
}

export interface LOGIN_USERVariables {
  email: string;
  password: string;
}

export default LOGIN_USER;
