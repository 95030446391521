import Box from "@material-ui/core/Box/Box";
import Button from "@material-ui/core/Button/Button";
import makeStyles from "@material-ui/core/styles/makeStyles";
import TextField from "@material-ui/core/TextField/TextField";
import Typography from "@material-ui/core/Typography/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";
import { Link } from "react-router-dom";

const useStyles = makeStyles({
  textfield: {
    width: "500px",
    height: "40px",
    background: "#dbdbdb26",
    borderRadius: "25px",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    marginBottom: "20px",
    "& .MuiInput-root": {
      width: "100%",
      height: "100%",
      borderRadius: "100px",
    },
    "& .MuiInput-underline": {
      "&:before": {
        width: "0%",
      },
      "&:after": {
        width: "90%",
        left: "5%",
      },
    },
  },
  container: {
    width: "100%",
    height: "100%",
    background: "url(https://www.starfarm.com/static/media/hero.dcc34059.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  contentContainer: {
    backgroundColor: "rgb(255 255 255 / 21%)",
    padding: "50px",
    borderRadius: "10px",
    width: "700px",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  link: {
    textDecoration: "none",
    color: "#02172a",
  },
  title: {
    color: "white",
    fontFamily: "simplifica",
    fontSize: "64px",
  },
  subtitle: { color: "rgba(84, 186, 180, 0.5)", marginBottom: "40px" },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  button: {
    marginTop: "35px",
    width: "205px",
    borderRadius: "25px",
  },
});

const inputProps = {
  min: 0,
  style: {
    textAlign: "center",
    padding: "0px",
    height: "100%",
    borderRadius: "100px",
  },
};

interface ILoginDesktopProps {
  handleSubmit: (email: string, password: string) => void;
  errormessage?: string;
  loading?: boolean;
  failed?: boolean;
}

const LoginDesktop: FC<ILoginDesktopProps> = ({
  handleSubmit,
  errormessage,
  loading = false,
  failed = false,
}) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();
    handleSubmit(email, password);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.contentContainer}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography variant="h2" className={classes.title}>
              StarFarm.
            </Typography>

            <Typography variant="h4" className={classes.subtitle}>
              We Grow Stars.
            </Typography>
          </Box>

          {loading === true && <CircularProgress />}
          {failed === true && (
            <Typography color="error" variant="body1">
              Retry log in.
            </Typography>
          )}
        </Box>

        <form onSubmit={handleSubmitForm}>
          <Box className={classes.formContainer}>
            <TextField
              value={email}
              onChange={handleEmailChange}
              placeholder="email@email.com"
              type="email"
              inputProps={inputProps as any}
              className={classes.textfield}
            />

            <TextField
              value={password}
              onChange={handlePasswordChange}
              placeholder="password"
              type="password"
              inputProps={inputProps as any}
              className={classes.textfield}
            />

            {errormessage && (
              <Typography color="error">{errormessage}</Typography>
            )}
            <Button
              type="submit"
              disabled={loading}
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Log In
            </Button>
          </Box>
        </form>

        <Box className={classes.buttonContainer}>
          <Link to="/registration" className={classes.link}>
            <Button
              type="button"
              variant="contained"
              className={classes.button}
            >
              Create An Account
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginDesktop;
