import useChart from "core/hooks/useCharts";
import convertPlaysToXY from "core/utils/convertPlaysToXY";
import React, { FC, useEffect, useMemo } from "react";
import { Line } from "react-chartjs-2";

interface IChartProps {
  plays?: Transaction[];
}
const Chart: FC<IChartProps> = ({ plays }) => {
  const playData = useMemo(() => {
    if (plays && plays.length >= 1) {
      return convertPlaysToXY(plays);
    }

    return {
      data: [
        { x: 0, y: 0 },
        { x: 10, y: 10 },
      ],
      labels: ["", ""],
    };
  }, [plays]);

  const chart = useChart();

  useEffect(() => {
    var gradient = chart.ref.current?.chartInstance.ctx!.createLinearGradient(
      0,
      0,
      0,
      400
    );
    gradient!.addColorStop(0, "rgba(86,194,188,0.46)");
    gradient!.addColorStop(0.5, "#02172A");

    chart.updateDataSet("Plays", playData.data, {
      label: "Plays",
      fill: true,
      backgroundColor: gradient,
      lineTension: 0.1,
      borderColor: plays && plays?.length >= 1 ? "#2e7362" : "transparent",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: plays && plays?.length >= 1 ? "#2e7362" : "transparent",
      pointBackgroundColor:
        plays && plays?.length >= 1 ? "#5a9c8c" : "transparent",
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor:
        plays && plays?.length >= 1 ? "#2e7362" : "transparent",
      pointHoverBorderColor:
        plays && plays?.length >= 1 ? "#2e7362" : "transparent",
      pointHoverBorderWidth: 2,
      pointRadius: 1,
      pointHitRadius: 10,
    });
  }, [playData, chart, plays]);

  const chartOptions = {
    plugins: {
      datalabels: {
        display: false,
      },
    },

    maintainAspectRatio: false,
    annotation: {
      events: [],
      passive: true,
    },
    elements: {
      line: {
        tension: 0.1,
      },
    },

    scales: {
      xAxes: [
        {
          id: "x-axis-1",
          gridLines: {
            display: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          id: "y-axis-1",
          gridLines: {
            borderDash: [5, 5],
            drawBorder: false,
          },
          ticks: {
            display: false,
          },
        },
      ],
    },
  } as any;



  return (
    <Line
      data={{
        labels: playData.labels,
        datasets: [],
      }}
      ref={chart.ref}
      options={chartOptions}
      legend={{ display: false }}
    />
  );
};

export default Chart;
