import React, { FC, useContext } from "react";
import { withStyles } from "@material-ui/core/styles";
import MoreButton from "./MoreButton";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { Link } from "react-router-dom";
import AddToPlaylistDialog from "../AddToPlaylistDialog";
import RemoveSongFromPlaylistButton from "../RemoveSongFromPlaylistButton";
import TipArtistModal from "../TipArtistModal";
import ReportFormContext from "stores/ReportFormStore";
import { observer } from "mobx-react-lite";
import TokenManager from "core/auth/TokenManager";
interface PlaylistMoreButtonProps {
  artistId?: string;
  albumId?: string;
  songId?: string;
  playlistMode?: boolean;
  playlistId?: string;
  artistName?: string;
  artistImage?: string;
}

const linkStyle = {
  textDecoration: "none",
  color: "rgba(255,255,255,0.75)",
};

const StyledMenuItem = withStyles((theme) => ({
  root: {
    color: "rgba(255, 255, 255, 0.75)",
    background: "rgba(16, 53, 77, 0.54",

    "&:focus": {
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "rgba(255, 255, 255, 0.75)",
      },
    },
    "&::hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)",
    },
  },
}))(MenuItem);

const PlaylistMoreButton: FC<PlaylistMoreButtonProps> = observer(
  ({
    artistId,
    albumId,
    songId,
    playlistMode = false,
    playlistId,
    artistName,
    artistImage,
  }) => {
    const store = useContext(ReportFormContext);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      setAnchorEl((e as any).currentTarget);
    };
    const manager = useContext(TokenManager);
    const token = manager.getToken();

    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleOpenReportModal = () => {
      if (token === 'undefined') {
        window.location.href = "/login"
      }
      else {
        store.openModal();
        store.setType("song");
        if (songId) {
          store.setId(songId);
        }
      }
    };

    return (
      <>
        <MoreButton
          anchor={anchorEl}
          setOpen={handleClick}
          setClose={setAnchorEl}
          dropDownIcon={<MoreHorizIcon />}
        >
          <StyledMenuItem onClick={handleClose}>
            <Link style={linkStyle} to={`/album/${albumId}`}>
              <ListItemText primary="Go To Album" />
            </Link>
          </StyledMenuItem>

          <StyledMenuItem onClick={handleClose}>
            <Link style={linkStyle} to={`/artist/${artistId}`}>
              <ListItemText primary="Go To Artist" />
            </Link>
          </StyledMenuItem>

          {playlistMode === true && (
            <StyledMenuItem onClick={handleClose}>
              <RemoveSongFromPlaylistButton
                songId={songId}
                playlistId={playlistId}
              />
            </StyledMenuItem>
          )}

          <StyledMenuItem>
            <TipArtistModal
              menuItem
              artistId={artistId}
              artistImage={artistImage}
              artistName={artistName}
            />
          </StyledMenuItem>

          <StyledMenuItem>
            <AddToPlaylistDialog closeMenu={handleClose} songId={songId} />
          </StyledMenuItem>

          <StyledMenuItem onClick={handleOpenReportModal}>
            <ListItemText primary="Report" />
          </StyledMenuItem>
        </MoreButton>
      </>
    );
  }
);

export default PlaylistMoreButton;
