import { DateTime } from "luxon";

const getTotalMonthlyCosts = (items?: Transaction[]) => {
  let totalMonthlyCost = 0;

  const todaysDate = DateTime.local();

  const filteredThisMonth = items?.filter(
    (item) =>
      DateTime.fromISO(item.createdAt!).get("month") ===
        todaysDate.get("month") &&
      DateTime.fromISO(item.createdAt!).get("year") === todaysDate.get("year")
  );

  filteredThisMonth?.forEach((item) => {
    totalMonthlyCost += item.total!;
  });

  return totalMonthlyCost / 100;
};
export default getTotalMonthlyCosts;
