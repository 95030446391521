import gql from "graphql-tag";

const addFunds = gql`
  mutation addFunds($amount: Float!, $stripePaymentMethodId: String) {
    addFunds(amount: $amount, stripePaymentMethodId: $stripePaymentMethodId) {
      _id
    }
  }
`;

export interface addFundsResponse {
  user: NewUser;
}

export interface addFundsVariables {
  amount: number;
  stripePaymentMethodId?: string;
}

export default addFunds;
