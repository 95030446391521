import { useRef, useCallback } from "react";
import { Line } from "react-chartjs-2";
import { DateTime } from "luxon";

import "chartjs-adapter-luxon";
import "chartjs-plugin-annotation";
import "chartjs-plugin-datalabels";
import useTheme from "@material-ui/core/styles/useTheme";

const updateOptions = {
  duration: 200,
  lazy: true,
};

const useChart = () => {
  const ref = useRef<Line>(null);

  const theme = useTheme();

  const clearAnnotations = useCallback(() => {
    (ref.current?.chartInstance?.options as any).annotation.annotations = [];
  }, []);

  const reset = useCallback(() => {
    const chart = ref?.current?.chartInstance;

    if (!chart) {
      return;
    }

    while ((chart.options as any).annotation.annotations.length > 0) {
      (chart.options as any).annotation.annotations.pop();
    }

    chart.data.labels = [];
    chart.data.datasets = [];

    chart.update({ duration: 0, lazy: false });
  }, []);

  const updateDataSet = useCallback((label, data, options = {}) => {
    const chart = ref?.current?.chartInstance;

    if (!data || !chart) {
      return;
    }

    if (!chart.data.datasets) {
      chart.data.datasets = [];
    }

    const dataset = chart.data.datasets.find(
      (dataset) => dataset.label === label
    );

    if (dataset) {
      dataset.data = data;

      if (options.datalabels) {
        dataset.borderColor = options.borderColor;
        dataset.backgroundColor = options.backgroundColor;
        dataset.datalabels = options.datalabels;
      }
    } else {
      chart.data.datasets.push({
        ...options,
        label,
        data,
      });
    }

    chart.update(updateOptions);
  }, []);

  const updateDateRange = useCallback((from: DateTime, to: DateTime) => {
    const chart = ref?.current?.chartInstance;

    if (!chart) {
      return;
    }

    if (!chart.options.scales?.xAxes) {
      return;
    }

    const index = chart.options.scales.xAxes.findIndex(
      (axis) => axis.id === "x-axis-1"
    );

    chart.options.scales.xAxes[index].ticks = {
      min: from.toMillis(),
      max: to.toMillis(),
    };

    chart.update({ duration: 250, lazy: false });
  }, []);

  const updatePriceRange = useCallback((min: number, max: number) => {
    const chart = ref?.current?.chartInstance;

    if (!chart) {
      return;
    }

    if (!chart.options.scales?.yAxes) {
      return;
    }

    const index = chart.options.scales.yAxes.findIndex(
      (axis) => axis.id === "y-axis-1"
    );

    chart.options.scales.yAxes[index].ticks = {
      ...chart.options.scales.yAxes[index].ticks,
      min,
      max,
    };

    chart.update({ duration: 250, lazy: true });
  }, []);

  const updateAnnotation = useCallback(
    (
      id: string,
      label?: string,
      value?: DateTime,
      style: any = { label: {}, line: {} }
    ) => {
      const chart = ref?.current?.chartInstance;

      if (!chart || !value) {
        return;
      }

      const annotations = (chart.options as any).annotation.annotations;
      const annotation = annotations.find((a: any) => a.id === id);

      if (annotation) {
        return;
      }

      const isDarkTheme = theme.palette.type === "dark";

      const labelStyle = label
        ? {
            content: label,
            backgroundColor: isDarkTheme ? "#222222" : "#222222",
            cornerRadius: 0,
            enabled: true,
            fontColor: isDarkTheme ? "#fff" : "#fff",
            fontSize: 10,
            position: "top",
            yPadding: 8,
            xPadding: 10,
            ...style.label,
          }
        : {};

      // TODO: Move to config file
      annotations.push({
        id,
        type: "line",
        drawTime: "beforeDatasetsDraw",
        mode: "vertical",
        scaleID: "x-axis-1",

        value: value.toMillis(),
        borderColor: isDarkTheme ? "#222" : "#222",
        borderWidth: 2,
        borderRadius: 4,
        ...style.annotation,
        label: labelStyle,
      });
    },
    [theme.palette.type]
  );

  return Object.freeze({
    ref,
    updateDataSet,
    updateDateRange,
    updatePriceRange,
    updateAnnotation,
    reset,
    clearAnnotations,
  });
};

export type UseChart = Readonly<{
  ref: React.RefObject<Line>;
  updateDataSet: (label: any, data: any, options?: any) => void;
  updateDateRange: (from: DateTime, to: DateTime) => void;
  updatePriceRange: (min: number, max: number) => void;
  updateAnnotation: (id: string, label: string, value: DateTime) => void;
  reset: () => void;
  clearAnnotations: () => void;
}>;

export default useChart;
