import gql from "graphql-tag";

const WALLET_BALANCE_SUB = gql`
  subscription {
    user: wallet {
      _id
      balanceInPennies
    }
  }
`;

export interface WalletBalanceSubResponse {
  user: NewUser;
}

export default WALLET_BALANCE_SUB;
