export const colors = [
  "rgb(244 67 54)",
  "rgb(104 102 138)",
  "rgb(185 149 39)",
  "rgb(60 125 63)",
  "rgb(125 45 85)",
  "rgb(187 99 48)",
  "rgb(189 59 50)",
  "rgb(67 44 109)",
  "rgb(156 100 98)",
  "rgb(99 125 68)",
];
