import gql from "graphql-tag";

const RESET_PASSWORD = gql`
  mutation resetPassword($resetPasswordToken: String!, $password: String!) {
    resetPassword(resetPasswordToken: $resetPasswordToken, password: $password)
  }
`;

export interface RESET_PASSWORDResponse {
  resetPassword: string;
}

export interface RESET_PASSWORDVariables {
  resetPasswordToken: string;
  password: string;
}

export default RESET_PASSWORD;
