import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React, { ChangeEvent, FC, FormEvent, useState } from "react";

import headphonesicon from "picture/headphones.png";

const useStyles = makeStyles((theme) =>
  createStyles({
    headphonesIcon: {
      backgroundImage: `url(${headphonesicon})`,
      width: "100px",
      height: "125px",
      backgroundSize: "cover",
      backgroundPositionX: "center",
      backgroundPositionY: "center",
      backgroundRepeat: "no-repeat",
      opacity: "0.75",
      marginBottom: "30px",
    },
    body: {
      background:
        "linear-gradient(167.96deg, #10354D 22.92%, #02172A 48.44%, #202256 89.58%)",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      overflow: "auto",
      minHeight: "100vh",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginBottom: "70px",
    },
    underline: {
      "&&&:before": {
        width: "0%",
      },
      "&&:after": {
        width: "100%",
      },
    },
    textfield: {
      width: "290px",
      height: "40px",
      background: "rgba(255, 255, 255, 0.1)",
      borderRadius: "25px",
      display: "flex",
      alignItems: "center",
      marginTop: "30px",
      dislpay: "flex",
      textAlign: "center",
      border: "none",
      marginBottom: 4,
      color: "#fff",
      "&:focus": {
        outline: "none",
        border: `.5px solid ${theme.palette.secondary.main}`,
      },
    },
    nextbutton: {
      // marginTop: "50px",
      backgroundColor: "#FD4060",
      width: "140px",
      height: "40px",
      borderRadius: "25px",
    },
    backButton: {
      // marginTop: "50px",
      backgroundColor: "rgba(93, 196, 178, 0.75)",
      width: "140px",
      height: "40px",
      borderRadius: "25px",
    },
  })
);

interface RegistrationPageTwo {
  handleBack: any;
  handleSubmitPassword: any;
  handleSubmitEmail: any;
  handleSignUp: any;
  isArtist?: boolean;
  handleSubmitArtistName?: any;
  handleSubmitFirstName: any;
  handleSubmitLastName: any;
  errorMessage?: string;
  loading?: boolean;
}

const RegistrationPageTwo: FC<RegistrationPageTwo> = ({
  handleBack,
  handleSubmitEmail,
  handleSubmitPassword,
  handleSignUp,
  isArtist = false,
  handleSubmitArtistName,
  handleSubmitFirstName,
  handleSubmitLastName,
  errorMessage,
  loading = false,
}) => {
  const classes = useStyles();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [emailValid, setEmailValid] = useState("");
  const [passwordValid, setPasswordValid] = useState("");
  const [confirmpasswordValid, setConfirmPasswordValid] = useState("");
  const [artistDisplayName, setArtistDisplayName] = useState("");
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");

  const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLastName(e.target.value);
  };

  const handleArtistDisplayNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setArtistDisplayName(e.target.value);
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);

    // eslint-disable-next-line
    const mailformat = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (e.target.value.match(mailformat)) {
      setEmailValid("true");
      return;
    }
    setEmailValid("false");
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    if (e.target.value.length >= 7) {
      setPasswordValid("true");
      return;
    }
    setPasswordValid("false");
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
    if (e.target.value === password) {
      setConfirmPasswordValid("true");
      return;
    }
    setConfirmPasswordValid("false");
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    handleSubmitPassword(password);
    handleSubmitArtistName?.(artistDisplayName);
    handleSubmitEmail(email);
    handleSubmitFirstName(firstname);
    handleSubmitLastName(lastname);
    handleSignUp();
  };

  if (errorMessage) {
    console.log("from page", errorMessage);
  }

  return (
    <>
      <Box className={classes.body}>
        <Box className={classes.container}>
          <Box className={classes.headphonesIcon}></Box>
          <Typography variant="h5">User Information</Typography>
          <form
            onSubmit={handleSubmit}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "290px",
            }}
          >
            {isArtist && (
              <input
                value={artistDisplayName}
                autoComplete="none"
                onChange={handleArtistDisplayNameChange}
                placeholder="Your Artist/Group Name."
                type="text"
                className={classes.textfield}
              />
            )}

            <input
              value={firstname}
              autoComplete="first-name"
              onChange={handleFirstNameChange}
              placeholder="First Name"
              type="text"
              className={classes.textfield}
            />

            <input
              value={lastname}
              autoComplete="last-name"
              onChange={handleLastNameChange}
              placeholder="Last Name"
              type="text"
              className={classes.textfield}
            />

            <input
              value={email}
              autoComplete="email"
              onChange={handleEmailChange}
              placeholder="Email@email.com"
              type="email"
              className={classes.textfield}
            />

            {emailValid === "true" && (
              <Typography variant="body2">Good.</Typography>
            )}
            {emailValid === "false" && (
              <Typography color="error" variant="body2">
                Email is Invalid
              </Typography>
            )}

            <input
              autoComplete="new-password"
              value={password}
              onChange={handlePasswordChange}
              placeholder="Password"
              type="password"
              className={classes.textfield}
            />

            {passwordValid === "true" && (
              <Typography variant="body2">Good.</Typography>
            )}
            {passwordValid === "false" && (
              <Typography color="error" variant="body2">
                Needs to be at least 7 characters long
              </Typography>
            )}

            <input
              autoComplete="new-password"
              value={confirmpassword}
              onChange={handleConfirmPasswordChange}
              placeholder="Confirm Password"
              type="password"
              className={classes.textfield}
            />

            {confirmpasswordValid === "true" && (
              <Typography variant="body2">Good.</Typography>
            )}
            {confirmpasswordValid === "false" && (
              <Typography color="error" variant="body2">
                Passwords Don't Match
              </Typography>
            )}

            {errorMessage && (
              <Typography
                color="error"
                style={{ textAlign: "center", marginTop: 20 }}
                variant="body2"
              >
                {errorMessage}
              </Typography>
            )}

            <Box mt={6}>
              {loading ? (
                <CircularProgress color="primary" />
              ) : (
                <Button type="submit" className={classes.nextbutton}>
                  <Typography>Sign Up</Typography>
                </Button>
              )}
            </Box>
          </form>
          <Button
            onClick={handleBack}
            style={{ marginTop: "30px" }}
            className={classes.backButton}
          >
            <Typography>Back</Typography>
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RegistrationPageTwo;
