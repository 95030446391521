import gql from "graphql-tag";

const CREATE_NEW_PLAYLIST = gql`
  mutation($name: String!, $image: String) {
    createNewPlaylist(name: $name, image: $image) {
      _id
    }
  }
`;

export interface CREATE_NEW_PLAYLISTVariables {
  name: string;
  image?: string;
}

export default CREATE_NEW_PLAYLIST;
